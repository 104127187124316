import ActiveCallControls from './active_call_controls';
import CancelWarmTransfer from 'actions/phone_call/cancel_warm_transfer';
import ClearAgentStatuses from 'actions/agent_status/clear_agent_statuses';
import connect from 'components/lib/connect';
import EndPhoneCall from 'actions/phone_call/end_phone_call';
import GetReadyAndBusyAgentStatuses from 'actions/agent_status/get_ready_and_busy_agent_statuses';
import HoldPhoneCall from 'actions/phone_call/hold_phone_call';
import MutePhoneCall from 'actions/phone_call/mute_phone_call';
import PhoneCall from 'models/phone_call';
import RemoveParticipantFromPhoneCall from 'actions/phone_call/remove_participant_from_phone_call';
import UpdatePresence from 'actions/agent_activity/update_presence';
import ObserverHide from 'actions/conversation_item/agent_coaching/observer_hide';
import ObserverShow from 'actions/conversation_item/agent_coaching/observer_show';

function mapStateToProps({ getProvider, isFeatureEnabled }) {
  let agents = getProvider('agents');
  let activeCallProvider = getProvider('activeCall');
  let activeCall = activeCallProvider.get();
  let currentAgent = getProvider('currentAgent').get();
  let isResponsePending = activeCallProvider.isPending();
  let currentAgentParticipantStatus = activeCall.conversationItem.content.findParticipantById(currentAgent.id).status;
  let customerParticipantStatus = activeCall.conversationItem.content.getCustomerParticipantStatus();
  let visibleObservers = activeCall.conversationItem.content.getVisibleObserverParticipants().map(observer => {
    let agent = agents.find(observer.participantId);
    return {
      id: agent.id,
      name: agent.getDisplayName(),
      avatarUrl: agent.avatarUrl,
      selected: observer.observerStatus === PhoneCall.PhoneCallObserverStatus.COACH,
    };
  });
  if (isResponsePending) {
    let pendingPhoneCall = activeCallProvider.getPending().conversationItem.content;
    currentAgentParticipantStatus = pendingPhoneCall.findParticipantById(currentAgent.id).status;
    customerParticipantStatus = pendingPhoneCall.getCustomerParticipantStatus();
  }
  const isCallQualityWarningEnabled = isFeatureEnabled('callQualityWarning');

  return {
    activeCall,
    currentAgent,
    currentAgentParticipantStatus,
    customerParticipantStatus,
    isCallQualityWarningEnabled,
    isRecording:
      activeCall.conversationItem.content.getLatestRecordingEventStatus() !== PhoneCall.RecordingStatus.NOT_RECORDING,
    isResponsePending,
    isHidden: activeCall.conversationItem.content.isHidden(currentAgent.id),
    visibleObservers,
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onCancelWarmTransfer: transferee => executeAction(CancelWarmTransfer, transferee),
    onCloseTransferDisplay: () => executeAction(ClearAgentStatuses),
    onEndPhoneCall: () => executeAction(EndPhoneCall),
    onHoldPhoneCall: ({ participantId, state }) => executeAction(HoldPhoneCall, { participantId, state }),
    onInterval: () => executeAction(UpdatePresence),
    onLeaveConference: participantId => executeAction(RemoveParticipantFromPhoneCall, { participantId }),
    onMutePhoneCall: state => executeAction(MutePhoneCall, state),
    onObserverHide: ({ customerId, conversationItemId }) =>
      executeAction(ObserverHide, { customerId, conversationItemId }),
    onObserverShow: ({ customerId, conversationItemId }) =>
      executeAction(ObserverShow, { customerId, conversationItemId }),
    onOpenTransferDisplay: () => executeAction(GetReadyAndBusyAgentStatuses),
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(ActiveCallControls);
