import AgentProfileEditor from './agent_profile_editor';
import AnswerPerformanceView from './answer_performance_view';
import AnswersPreview from './answers_preview';
import Boot from './boot';
import CustomerCombine from './customer_combine';
import CustomerView from './customer_view';
import CustomReporting from './custom_reporting';
import Datasets from './datasets';
import ExternalCustomerLookup from './external_customer_lookup';
import ForgotPassword from './forgot_password';
import Floorboard from './floorboard';
import Inbox from './inbox';
import KbAdmin from './kb_admin';
import KeywordGroups from './keyword_groups';
import Liveboards from './liveboards';
import RecommendedAnswers from './recommended_answers';
import Reports from './reports';
import Search from './search';
import SnippetEditor from './snippet_editor';
import TeamEditor from './team_editor';
import Topics from './topics';
import UserActivation from './user_activation';
import UsersAdmin from './users_admin';
import UserEditor from './user_editor';
import UserLogin from './user_login';
import UserResetPassword from './user_reset_password';

let locationClasses = [
  AgentProfileEditor,
  AnswerPerformanceView,
  AnswersPreview,
  Boot,
  CustomerCombine,
  CustomerView,
  CustomReporting,
  Datasets,
  ExternalCustomerLookup,
  ForgotPassword,
  Floorboard,
  Inbox,
  KbAdmin,
  KeywordGroups,
  Liveboards,
  RecommendedAnswers,
  Reports,
  Search,
  SnippetEditor,
  TeamEditor,
  Topics,
  UserActivation,
  UserEditor,
  UserLogin,
  UserResetPassword,
  UsersAdmin,
];

export default Object.freeze(locationClasses.map(c => [c.typeName, c]));
