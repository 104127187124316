import StandardGateway from './lib/standard_gateway';

export default class AnswerPerformanceGateway extends StandardGateway {
  constructor(backend) {
    super(backend, 'v1/orgs/:orgId/answer-performance');
  }

  fetchAll(params) {
    const dateRangeQuery = params?.dateRange ? { 'f.t': params.dateRange } : {};
    const audienceIdQuery = params?.audienceId ? { 'f.aid': params.audienceId } : {};
    return super.fetchAll(params, { ...dateRangeQuery, ...audienceIdQuery });
  }
}
