import { some } from 'lodash';

import Err from 'models/err';

export function filterPasswordErrors(errors) {
  return errors.filter(e => e.attr === 'password');
}

export function hasNotAllowedWithSSOError(errors) {
  return some(errors, isNotAllowedWithSSO);
}

export function isUnauthorized(errors) {
  return some(errors, err => isInvalidAuth(err) || isNotAllowedWithSSO(err));
}

export function isInvalidAuth(err) {
  return !err.attr && err.code === Err.Code.INVALID;
}

export function isInvalidHost(err) {
  return err.attr === 'host' && err.code === Err.Code.INVALID;
}

export function isNotAllowedWithSSO(err) {
  return err.code === Err.Code.FEATURE_DISABLED && err.meta?.reason === 'SSO_ENABLED';
}
