import _ from 'lodash';
import moment from 'moment';

import createEnum from 'scripts/lib/create_enum';
import createModel from './lib/create_model';
import ServerClock from 'scripts/application/lib/server_clock';
import RecordingSummary from './recording_summary';

const prop = createModel.prop;

export const TransferType = createEnum('COLD', 'WARM');
export const TransferStatus = createEnum(
  'INITIATED',
  'CANCELLED',
  'COMPLETED',
  'IN_PROGRESS',
  'NOT_AVAILABLE',
  'ERROR'
);

export const Transfer = createModel({
  modelName: 'PhoneCallTransfer',

  properties: {
    id: String,
    fromParticipantId: String,
    hasAgentClickedCancel: prop(Boolean).default(false),
    toRoutingGroupId: String,
    toParticipantId: String,
    status: prop().oneOf(..._.keys(TransferStatus)),
    type: prop().oneOf(..._.keys(TransferType)),
  },

  isActive() {
    return this.getStatus() === TransferStatus.IN_PROGRESS || this.getStatus() === TransferStatus.INITIATED;
  },
  getStatus() {
    if (this.hasAgentClickedCancel) {
      return TransferStatus.CANCELLED;
    }
    return this.status;
  },
  cancelTransfer() {
    this.hasAgentClickedCancel = true;
  },
});

const PhoneCall = createModel({
  modelName: 'PhoneCall',

  properties: {
    answeredAt: String,
    callId: String,
    callQualityEvents: prop(Object).default([]),
    companyNumber: String,
    completedAt: String,
    customerNumber: String,
    forwardedTo: String,
    hasVoicemail: prop(Boolean).default(false),
    isAbandoned: prop(Boolean).default(false),
    participants: prop(Object).default([]),
    participantEvents: prop(Object).default([]),
    recordingDuration: Number, // in seconds
    recordingEvents: prop([Object]).default([]),
    recordingId: String,
    recordingSummary: prop(RecordingSummary),
    recordingStatus: String,
    startedAt: String,
    status: String,
    transcribedText: String,
    transfer: prop(Transfer),
  },

  makeCall(companyNumber, customerNumber) {
    return new PhoneCall(
      _.merge({}, this, {
        status: PhoneCall.Status.OUTGOING,
        customerNumber,
        companyNumber,
      })
    );
  },

  isActive() {
    return (
      [
        PhoneCall.Status.ABANDONED,
        PhoneCall.Status.COMPLETED,
        PhoneCall.Status.DECLINED,
        PhoneCall.Status.ERROR,
        PhoneCall.Status.RECEIVING,
      ].indexOf(this.status) === -1
    );
  },

  getLatestRecordingEventStatus() {
    return _.isEmpty(this.recordingEvents)
      ? PhoneCall.RecordingStatus.NOT_RECORDING
      : _.last(this.recordingEvents).status;
  },

  // this method is currently only used for unit testing
  addRecordingEvent(status) {
    this.recordingEvents.push({ status });
  },

  isOfferingOrLiveCall() {
    return this.status === PhoneCall.Status.OFFERING || this.isLive() || this.isOutgoing();
  },

  isOutgoing() {
    return this.status === PhoneCall.Status.OUTGOING;
  },

  isReceiving() {
    return this.status === PhoneCall.Status.RECEIVING;
  },

  isWaiting() {
    return (
      this.status === PhoneCall.Status.INCOMING ||
      this.status === PhoneCall.Status.QUEUED ||
      this.status === PhoneCall.Status.OFFERING
    );
  },

  isLive() {
    return this.status === PhoneCall.Status.IN_PROGRESS || this.status === PhoneCall.Status.WARM_TRANSFER;
  },

  isBlindTransferring() {
    // our best guess involves detecting a call with the OFFERING status that has been previously answered
    return this.status === PhoneCall.Status.OFFERING && this.answeredAt != null;
  },

  isWarmTransferRecipient(agentId) {
    return (
      !!this.transfer &&
      this.transfer.getStatus() === TransferStatus.INITIATED &&
      (!this.transfer.toParticipantId || this.transfer.toParticipantId === agentId)
    );
  },

  isHidden(agentId) {
    let agentParticipant = this.findParticipantById(agentId);
    if (!agentParticipant) {
      return true;
    }

    return agentParticipant.observerVisibility !== PhoneCall.ObserverVisibility.VISIBLE;
  },

  isTransferee(agentId) {
    if (!this.transfer) {
      return false;
    }
    return this.transfer.toParticipantId === agentId;
  },

  isTransfereeOnCall() {
    if (!this.transfer) {
      return false;
    }
    return this.isActiveParticipant(this.transfer.toParticipantId);
  },

  canCompleteTransfer() {
    return this.getTransferStatus() === TransferStatus.IN_PROGRESS;
  },

  isActiveParticipant(agentId) {
    let participant = this.findParticipantById(agentId);
    if (!participant) {
      return false;
    }
    return PhoneCall.isActiveParticipant(participant);
  },

  getTransferStatus() {
    if (!this.transfer) {
      return;
    }
    return this.transfer.getStatus();
  },

  getIncomingCallType(agentId) {
    if (this.isBlindTransferring()) {
      return PhoneCall.IncomingCallType.BLIND_TRANSFER;
    } else if (this.isWarmTransferRecipient(agentId)) {
      return PhoneCall.IncomingCallType.WARM_TRANSFER;
    }
    return PhoneCall.IncomingCallType.INCOMING;
  },

  calculateDuration() {
    if (this.recordingDuration) {
      return this.recordingDuration;
    }
    if (this.completedAt && (this.answeredAt || this.startedAt)) {
      return moment(this.completedAt).diff(moment(this.answeredAt || this.startedAt), 'seconds');
    }
    if (this.answeredAt || this.startedAt) {
      return moment().diff(moment(this.answeredAt || this.startedAt), 'seconds');
    }
    return null;
  },

  addParticipant(participant) {
    _.remove(this.participants, p => p.participantId === participant.participantId);
    this.participants.push(participant);
    this.participantEvents.push({
      eventTime: ServerClock.toISOString(),
      participantId: participant.participantId,
      participantStatus: participant.status,
    });
  },

  addTransfer({ fromAgentId, toAgentId, status }) {
    this.transfer = new Transfer({
      toParticipantId: toAgentId,
      fromParticipantId: fromAgentId,
      status,
    });
  },

  setParticipantStatus(participantId, status) {
    let participant = this.findParticipantById(participantId);
    if (participant) {
      participant.status = status;
      this.participantEvents.push({
        eventTime: ServerClock.toISOString(),
        participantId,
        participantStatus: status,
      });
    }
  },

  isDialing(participantId) {
    return this._doesParticipantHaveStatus(participantId, PhoneCall.ParticipantStatus.DIALING);
  },

  isOnHold(participantId) {
    return this._doesParticipantHaveStatus(participantId, PhoneCall.ParticipantStatus.ON_HOLD);
  },

  isHoldRequested(participantId) {
    return this._doesParticipantHaveStatus(participantId, PhoneCall.ParticipantStatus.HOLD_REQUESTED);
  },

  isMuted(participantId) {
    return this._doesParticipantHaveStatus(participantId, PhoneCall.ParticipantStatus.MUTED);
  },

  isMuteRequested(participantId) {
    return this._doesParticipantHaveStatus(participantId, PhoneCall.ParticipantStatus.MUTE_REQUESTED);
  },

  isParticipantDisconnecting(participantId) {
    return this._doesParticipantHaveStatus(participantId, PhoneCall.ParticipantStatus.DISCONNECTING);
  },

  // `isAbandoned` field is set by the backend for calls that have been abandoned. For calls that were abandoned
  // before this field was added, the call is deemed as abandoned if there is no agent participant for a COMPLETED call
  isAbandonedCall() {
    if (this.isAbandoned) {
      return true;
    }

    return (
      this.status === PhoneCall.Status.COMPLETED &&
      !this.answeredAt &&
      !this.hasVoicemail &&
      !this.forwardedTo &&
      !this.participants.some(
        participant =>
          participant.type === PhoneCall.ParticipantType.AGENT &&
          (PhoneCall.isActiveParticipant(participant) || participant.status === PhoneCall.ParticipantStatus.HUNG_UP)
      )
    );
  },

  _doesParticipantHaveStatus(participantId, status) {
    return _.findIndex(this.participants, { participantId, status }) !== -1;
  },

  isParticipantAgent(agentId) {
    let agentParticipant = this.findParticipantById(agentId);
    if (!agentParticipant) {
      return false;
    }
    return agentParticipant.type === PhoneCall.ParticipantType.AGENT;
  },

  isParticipantObserver(agentId) {
    let agentParticipant = this.findParticipantById(agentId);
    if (!agentParticipant) {
      return false;
    }
    return agentParticipant.type === PhoneCall.ParticipantType.OBSERVER;
  },

  getObserverParticipants() {
    return this.participants.filter(p => p.type === PhoneCall.ParticipantType.OBSERVER);
  },

  getActiveObserverParticipants() {
    return this.getObserverParticipants().filter(p => PhoneCall.isActiveParticipant(p));
  },

  getJoinedObserverParticipants() {
    return this.getActiveObserverParticipants().filter(
      p => p.observerStatus === PhoneCall.PhoneCallObserverStatus.JOIN
    );
  },

  getJoinedObserverParticipantIds() {
    return this.getJoinedObserverParticipants().map(p => p.participantId);
  },

  getVisibleObserverParticipants() {
    return this.getActiveObserverParticipants().filter(
      p => p.observerVisibility === PhoneCall.ObserverVisibility.VISIBLE
    );
  },

  getActiveAgentParticipants() {
    return this.getAgentParticipants().filter(p => PhoneCall.isActiveParticipant(p));
  },

  getAgentParticipants() {
    let participants = this.participants.filter(p => p.type === PhoneCall.ParticipantType.AGENT);

    // an observer that is also a tranferee is also counted as an agent on the call
    if (this.transfer && this.transfer.isActive()) {
      let participant = this.findParticipantById(this.transfer.toParticipantId);
      if (
        participant &&
        this.isParticipantObserver(participant.participantId) &&
        PhoneCall.isActiveParticipant(participant)
      ) {
        participants.push(participant);
      }
    }

    return participants;
  },

  getAgentParticipantIds() {
    return this.getAgentParticipants().map(p => p.participantId);
  },

  getAgentParticipantsOnCall() {
    return this.getAgentParticipants().filter(p => PhoneCall.isParticipantOnCall(p));
  },

  getAgentParticipantIdsOnCall() {
    return this.getAgentParticipantsOnCall().map(p => p.participantId);
  },

  getExternalActiveParticipants() {
    return this.participants.filter(
      p => p.type === PhoneCall.ParticipantType.EXTERNAL && PhoneCall.isActiveParticipant(p)
    );
  },

  getExternalActiveParticipantIds() {
    return this.getExternalActiveParticipants().map(p => p.participantId);
  },

  getParticipantUpdatedAt(participantId) {
    let participant = this.findParticipantById(participantId);
    return participant && participant.statusUpdatedAt;
  },

  getCustomerParticipantStatus() {
    const customerParticipant = _.find(this.participants, { type: PhoneCall.ParticipantType.CUSTOMER });
    return customerParticipant && customerParticipant.status;
  },

  getActiveCallQualityEventsForAgent(agentId) {
    const callQualityEventMap = {};
    // call quality event objects will have a status of 'STARTED' when the issue is first detected.
    // once the issue is resolved, a new event object with the same eventType will be created with a status of 'ENDED'.
    _.forEach(this.callQualityEvents, event => {
      if (event.agentId !== agentId) {
        return;
      }
      callQualityEventMap[event.eventType] = event.status;
    });
    return _.reduce(
      callQualityEventMap,
      (activeEvents, status, eventType) => {
        if (status === PhoneCall.CallQualityEventStatus.STARTED) {
          activeEvents.push(eventType);
        }
        return activeEvents;
      },
      []
    );
  },

  findParticipantById(participantId) {
    return _.find(this.participants, { participantId });
  },

  fileDescriptor() {
    let filename = `${this.customerNumber}-phonecall`;
    if (this.startedAt) {
      filename = filename.concat('-').concat(moment(this.startedAt).format('YYYY-MM-DD HH-mm'));
    }
    return {
      filename: `${filename}.mp3`,
    };
  },

  getCallLegType(participantId) {
    let participant = this.findParticipantById(participantId);
    return participant && participant.callLegType;
  },

  isInteractiveCall() {
    // there is currently no direct way to determine whether or not a call
    // is/was interactive (I.E. non telephony API); currently only Twilio accounts
    // are interactive and only the Twilio the gateway sets the callId attribute;
    // so if the callId attribute is present then this must be an "interactive" call
    if (this.callId) {
      return true;
    }

    return false;
  },

  isTransferLive() {
    if (
      !this.transfer ||
      (this.transfer.status !== TransferStatus.IN_PROGRESS && this.transfer.status !== TransferStatus.INITIATED)
    ) {
      return false;
    }

    const activeAgentParticipants = this.participants
      .filter(p => p.type === PhoneCall.ParticipantType.AGENT || p.type === PhoneCall.ParticipantType.OBSERVER)
      .filter(p => PhoneCall.isActiveParticipant(p));

    return activeAgentParticipants.length >= 2;
  },

  statics: {
    create(attrs) {
      return new this(_.merge({ startedAt: ServerClock.toISOString() }, attrs));
    },

    isActiveParticipant: participant => {
      return (
        PhoneCall.isParticipantOnCall(participant) ||
        [PhoneCall.ParticipantStatus.DIALING, PhoneCall.ParticipantStatus.TERMINATING].indexOf(participant.status) !==
          -1
      );
    },

    didParticipantCancel: participant => {
      return (
        PhoneCall.isParticipantOnCall(participant) ||
        [PhoneCall.ParticipantStatus.NO_ANSWER, PhoneCall.ParticipantStatus.CANCELED].indexOf(participant.status) !== -1
      );
    },

    isLiveConference: participants => {
      const externalActiveParticipants = _.filter(
        participants,
        participant =>
          participant.type === PhoneCall.ParticipantType.EXTERNAL && PhoneCall.isActiveParticipant(participant)
      );
      return externalActiveParticipants.length >= 1;
    },

    isAnyExternalParticipantBeingDialed: participants => {
      let isParticipantBeingDialed = false;
      _.forEach(participants, participant => {
        if (
          participant.type === PhoneCall.ParticipantType.EXTERNAL &&
          participant.status === PhoneCall.ParticipantStatus.DIALING
        ) {
          isParticipantBeingDialed = true;
        }
      });
      return isParticipantBeingDialed;
    },

    isParticipantUnavailable: participant => {
      return (
        [
          PhoneCall.ParticipantStatus.BUSY,
          PhoneCall.ParticipantStatus.CANCELED,
          PhoneCall.ParticipantStatus.FAILED,
          PhoneCall.ParticipantStatus.NO_ANSWER,
        ].indexOf(participant.status) !== -1
      );
    },

    isParticipantOnCall: participant => {
      return (
        [
          PhoneCall.ParticipantStatus.ACTIVE,
          PhoneCall.ParticipantStatus.DISCONNECTING,
          PhoneCall.ParticipantStatus.HOLD_REQUESTED,
          PhoneCall.ParticipantStatus.MUTE_REQUESTED,
          PhoneCall.ParticipantStatus.MUTED,
          PhoneCall.ParticipantStatus.ON_HOLD,
          PhoneCall.ParticipantStatus.TRANSFER_REQUESTED,
        ].indexOf(participant.status) !== -1
      );
    },

    Status: createEnum(
      'ABANDONED',
      'COMPLETED',
      'DECLINED',
      'ERROR',
      'INCOMING',
      'IN_PROGRESS',
      'NO_ANSWER',
      'OFFERING',
      'OUTGOING',
      'QUEUED',
      'RECEIVING',
      'WARM_TRANSFER'
    ),
    ParticipantStatus: createEnum(
      'ACTIVE',
      'BUSY',
      'CANCELED',
      'DIALING',
      'DISCONNECTING',
      'FAILED',
      'HOLD_REQUESTED',
      'HUNG_UP',
      'INITIATING',
      'MUTE_REQUESTED',
      'MUTED',
      'NO_ANSWER',
      'ON_HOLD',
      'TERMINATING',
      'TRANSFER_REQUESTED',
      'UNKNOWN'
    ),
    ParticipantType: createEnum('AGENT', 'CUSTOMER', 'EXTERNAL', 'OBSERVER'),
    RecordingStatus: createEnum('IN_PROGRESS', 'NOT_RECORDING', 'PAUSED', 'STOPPED'),
    IncomingCallType: createEnum('BLIND_TRANSFER', 'INCOMING', 'WARM_TRANSFER'),
    PhoneCallObserverStatus: createEnum('COACH', 'JOIN', 'LISTEN'),
    ObserverVisibility: createEnum('HIDDEN', 'VISIBLE'),
    CallQualityEventStatus: createEnum('STARTED', 'ENDED'),
  },
});
export default PhoneCall;
