import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import BubbleContent from '../content/bubble_content';
import ConversationItem from 'models/conversation_item';
import ConversationMessageAttachments from 'components/customer/conversation_history/conversation_items_v2/message/attachments/conversation_message_attachments';
import RedactedItem from 'components/customer/conversation_history/conversation_items_v2/redacted_item';
import SafeHtml from 'components/common/safe_html';

export default function ChatHTMLContent({ className, item, status }) {
  return (
    <BubbleContent
      className={classnames('chatItem-content html', className)}
      data-aid={`chatItem-content-${item.id}`}
      item={item}
      status={status}
    >
      {renderBody()}
      {renderAttachments()}
    </BubbleContent>
  );

  function renderBody() {
    if (item.content.isRedacted) {
      return <RedactedItem />;
    }
    const html = _.get(item, 'content.text').replace(/(?<=>)\s*\n\s*(?=<)/g, '');
    return <ChatSafeHTML className="chatItem-content-rich" html={html} setDirection />;
  }

  function renderAttachments() {
    let itemAttachments = item.content.attachments;
    if (!itemAttachments || itemAttachments.length === 0) {
      return null;
    }
    return <ConversationMessageAttachments attachments={itemAttachments} />;
  }
}

export const ChatSafeHTML = styled(SafeHtml)`
  box-shadow: none;
  outline: none;
  *:first-child {
    margin-top: 0;
  }
  *:last-child {
    margin-bottom: 0;
  }
  pre {
    white-space: pre-wrap;
  }
  ol {
    margin-top: 0px;
    margin-left: 15px;
    padding-left: 0px;

    li {
      list-style-position: outside;
      padding-left: 0px;
      padding-top: 10px;

      p {
        margin: 0;
      }
    }
  }
  ul {
    margin-top: 0px;
    margin-left: 15px;
    padding-left: 0px;

    li {
      list-style-position: outside;
      padding-left: 0px;
      padding-top: 0px;

      p {
        margin: 0;
      }
    }
  }
`;

ChatHTMLContent.propTypes = {
  className: PropTypes.string,
  item: PropTypes.instanceOf(ConversationItem).isRequired,
  status: PropTypes.string,
};
