export default function createSimulationKeyMap(runnerContext) {
  return {
    65: () => runnerContext.gateways.commSimulation.simulatePhoneCallAccepted(), // a key
    66: () => runnerContext.gateways.commSimulation.simulateBlindTransferReceived(), // b key
    67: () => runnerContext.gateways.commSimulation.simulateInitialChatReceived(), // c key
    68: () => runnerContext.gateways.commSimulation.simulatePhoneCallQualityEvent('low-mos', 'ENDED'), // d key
    69: () => runnerContext.gateways.commSimulation.simulateInitialEmailReceived(), // e key
    70: () => runnerContext.gateways.commSimulation.simulateFbMessageReceived(), // f key
    72: () => runnerContext.gateways.commSimulation.simulatePhoneCallEnd(), // h key
    78: () => runnerContext.gateways.commSimulation.simulateNoteReceived(), // n key
    79: () => runnerContext.gateways.commSimulation.simulateWarmTransfer(), // o key
    80: () => runnerContext.gateways.commSimulation.simulatePhoneCallReceived(), // p key
    81: () => runnerContext.gateways.commSimulation.simulatePhoneCallQualityEvent('low-mos', 'STARTED'), // q key
    82: () => runnerContext.gateways.commSimulation.simulateEmailSendFailure(), // r key
    83: () => runnerContext.gateways.commSimulation.simulateSmsReceived(), // s key
    84: () => runnerContext.gateways.commSimulation.simulateTweetReceived(), // t key
    85: () => runnerContext.gateways.commSimulation.simulatePhoneCallUnoffered(), // u key
    87: () => runnerContext.gateways.commSimulation.simulateWebFormReceived(), // w key
    88: () => runnerContext.gateways.commSimulation.simulateTaskReceived(), // x key
    90: () => runnerContext.gateways.commSimulation.simulateEmailResponseReceived(), // z key
  };
}
