import createLocationModel from './lib/create_location_model';

export default createLocationModel({
  modelName: 'RecommendedAnswers',
  properties: {},

  statics: {
    breadcrumb: 'Recommended Answers',
  },
});
