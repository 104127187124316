import { CustomersProvider } from 'scripts/adapters/stores/customers_store';

export default function configureProviders(stores) {
  return {
    accounts: stores.accounts.getProvider(),
    activeCall: stores.activeCall.getProvider(),
    activeSessions: stores.activeSessions.getProvider(),
    agents: stores.agents.getProvider(),
    agentAssistanceConfig: stores.agentAssistanceConfig.getProvider(),
    agentExperienceConfig: stores.agentExperienceConfig.getProvider(),
    agentInboxItems: stores.agentInboxItems.getProvider(),
    agentInboxItemsNew: stores.agentInboxItemsNew.getProvider(),
    agentInboxMeta: stores.agentInboxMeta.getProvider(),
    agentNotificationMeta: stores.agentNotificationMeta.getProvider(),
    agentNotifications: stores.agentNotifications.getProvider(),
    agentPreferences: stores.agentPreferences.getProvider(),
    agentProfiles: stores.agentProfiles.getProvider(),
    agentRoutingPreferences: stores.agentRoutingPreferences.getProvider(),
    agentSearchResults: stores.agentSearchResults.getProvider(),
    agentStatus: stores.agentStatus.getProvider(),
    agentStatuses: stores.agentStatuses.getProvider(),
    agentStatusReasons: stores.agentStatusReasons.getProvider(),
    answerPanel: stores.answerPanel.getProvider(),
    answerPanelState: stores.answerPanelState.getProvider(),
    answerPerformances: stores.answerPerformances.getProvider(),
    answerSuggestions: stores.answerSuggestions.getProvider(),
    apiTokens: stores.apiTokens.getProvider(),
    appFeatures: stores.appFeatures.getProvider(),
    audiences: stores.audiences.getProvider(),
    auth: stores.auth.getProvider(),
    channelConfiguration: stores.channelConfiguration.getProvider(),
    commentPanel: stores.commentPanel.getProvider(),
    communicationQueues: stores.communicationQueues.getProvider(),
    companyProfile: stores.companyProfile.getProvider(),
    connectionState: stores.connectionState.getProvider(),
    conversationMerge: stores.conversationMerge.getProvider(),
    conversationWorkflow: stores.conversationWorkflow.getProvider(),
    conversationWorkflowConfig: stores.conversationWorkflowConfig.getProvider(),
    embedTokensReport: stores.embedTokensReport.getProvider(),
    customerAccessHistory: stores.customerAccessHistory.getProvider(),
    currentAgent: stores.currentAgent.getProvider(),
    currentAgentSnippets: stores.currentAgentSnippets.getProvider(),
    currentLocation: stores.currentLocation.getProvider(),
    customAttributes: stores.customAttributes.getProvider(),
    customers: new CustomersProvider(stores.customers),
    customerMerge: stores.customerMerge.getProvider(),
    customerProfileDef: stores.customerProfileDef.getProvider(),
    customerUnlink: stores.customerUnlink.getProvider(),
    customChannels: stores.customChannels.getProvider(),
    customChannelsConfig: stores.customChannelsConfig.getProvider(),
    emailSuggestions: stores.emailSuggestions.getProvider(),
    embeddedReport: stores.embeddedReport.getProvider(),
    externalActionForm: stores.externalActionForm.getProvider(),
    externalCustomerSearchResults: stores.externalCustomerSearchResults.getProvider(),
    favoriteAnswers: stores.favoriteAnswers.getProvider(),
    greetingSuggestions: stores.greetingSuggestions.getProvider(),
    highlightedMessages: stores.highlightedMessages.getProvider(),
    inbox: stores.inbox.getProvider(),
    integrations: stores.integrations.getProvider(),
    ivrs: stores.ivrs.getProvider(),
    kbVariables: stores.kbVariables.getProvider(),
    languages: stores.languages.getProvider(),
    liveboard: stores.liveboard.getProvider(),
    locationHistory: stores.locationHistory.getProvider(),
    messagingConfiguration: stores.messagingConfiguration.getProvider(),
    modal: stores.modal.getProvider(),
    notifications: stores.notifications.getProvider(),
    organization: stores.organization.getProvider(),
    performExternalAction: stores.performExternalAction.getProvider(),
    personalInboxCounts: stores.personalInboxCounts.getProvider(),
    phraseSuggestions: stores.phraseSuggestions.getProvider(),
    recentCustomers: stores.recentCustomers.getProvider(),
    recentlyMentionedAgentIds: stores.recentlyMentionedAgentIds.getProvider(),
    relationshipLabels: stores.relationshipLabels.getProvider(),
    report: stores.report.getProvider(),
    reportBuilderConfig: stores.reportBuilderConfig.getProvider(),
    reportConfigs: stores.reportConfigs.getProvider(),
    routableCounts: stores.routableCounts.getProvider(),
    routingGroups: stores.routingGroups.getProvider(),
    scheduledReport: stores.scheduledReport.getProvider(),
    selectedKbItem: stores.selectedKbItem.getProvider(),
    selectedSuggestedReply: stores.selectedSuggestedReply.getProvider(),
    sharedReportConfigs: stores.sharedReportConfigs.getProvider(),
    snippetComposition: stores.snippetComposition.getProvider(),
    snippetLinks: stores.snippetLinks.getProvider(),
    snippetRevisions: stores.snippetRevisions.getProvider(),
    snippets: stores.snippets.getProvider(),
    snippetSearchResult: stores.snippetSearchResult.getProvider(),
    snippetView: stores.snippetView.getProvider(),
    stationConfigurations: stores.stationConfigurations.getProvider(),
    systemConfiguration: stores.systemConfiguration.getProvider(),
    taskInfo: stores.taskInfo.getProvider(),
    teamInboxCounts: stores.teamInboxCounts.getProvider(),
    teams: stores.teams.getProvider(),
    toasts: stores.toasts.getProvider(),
    toastsDeprecated: stores.toastsDeprecated.getProvider(),
    topics: stores.topics.getProvider(),
    topicHierarchy: stores.topicHierarchy.getProvider(),
    topicSuggestions: stores.topicSuggestions.getProvider(),
    userCredential: stores.userCredential.getProvider(),
    userEditor: stores.userEditor.getProvider(),
    users: stores.users.getProvider(),
    universalSearchResults: stores.universalSearchResults.getProvider(),
    usersUpload: stores.usersUpload.getProvider(),
    voiceConfiguration: stores.voiceConfiguration.getProvider(),
    webhooks: stores.webhooks.getProvider(),
    widgetConfigurations: stores.widgets.getProvider(),
  };
}

/**
 * `createGetProvider` returns a function which takes either a provider name such as
 * "rules" or a period-separated path to a nested provider, such as "customers.5.profile".
 *
 * @param {string} providers
 */
export function createGetProvider(providers) {
  return name => {
    const selectors = name.split('.');
    const provider = providers[selectors[0]];
    if (selectors.length === 1) {
      return provider;
    } else if (selectors[0] === 'customers') {
      const customerProvider = providers.customers.getProvider(selectors[1]);
      if (selectors.length === 2) {
        return customerProvider;
      }
      return customerProvider.getProvider(selectors[2]);
    }

    return undefined;
  };
}
