import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { spaceFor } from 'components/space_helper';

const fixedHead = css`
  thead {
    position: relative;
  }
  th {
    position: sticky;
    top: -1px;
    z-index: 1;
  }
`;

const TBody = styled.tbody``;

const THead = styled.thead`
  background-color: ${p => p.theme.colors.gray300};
`;

const TH = styled.th`
  background-color: ${p => p.theme.colors.gray300};
  color: ${p => p.theme.colors.gray800};
  text-align: left;
`;

const TFoot = styled.tfoot``;

const TR = styled.tr`
  padding: ${p => spaceFor('inset', p)};
`;

const TD = styled.td`
  padding: ${p => spaceFor('inset', p)};
`;

const Table = styled.table`
  border: 1px solid ${p => p.theme.colors.gray200};
  border-radius: ${p => p.theme.borderRadius.default};
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  & tbody tr:nth-child(even) {
    background-color: ${p => (p.zebra ? p.theme.colors.gray100 : 'transparent')};
  }

  ${TD}, ${TH} {
    padding: ${p => spaceFor('inset', p)};
  }
  ${p => p.stickyHeader && fixedHead};
`;

Table.propTypes = {
  zebra: PropTypes.bool,
  inset: PropTypes.oneOf(['none', 'small', 'medium', 'large']),
  // When the header reaches the top of the viewport, the header will stick and become fixed.
  stickyHeader: PropTypes.bool,
};

Table.defaultProps = {
  inset: 'small',
  stickyHeader: false,
};

export { Table, TBody, THead, TFoot, TR, TD, TH };
