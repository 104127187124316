import _ from 'lodash';
import classnames from 'classnames';
import styled from 'styled-components';
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import ActiveCall from 'models/active_call';
import ActiveCallPhoneNumberContainer from './active_call_phone_number_container';
import AgentProfile from 'models/agent_profile';
import Button, { ButtonTypes } from 'components/common/button';
import CallCoachingPillsContainer from './call_coaching_pills';
import CallQualityWarning from 'components/phone_controls/call_quality_warning';
import ConferenceButton from './buttons/conference_button';
import ConferenceContainer from './conference/conference';
import LeaveCallButton from './buttons/leave_call_button';
import HoldButton from './buttons/hold_button';
import Keypad from './keypad/keypad';
import KeypadButton from './buttons/keypad_button';
import MuteButton from './buttons/mute_button';
import ObserverPill from './observer_pill';
import ParticipantsButton from './buttons/participants_button';
import ParticipantListContainer from './participants/participant_list';
import PhoneCall from 'models/phone_call';
import RecordingControls from 'components/phone_controls/recording_controls';
import Tooltip from 'components/common/tooltip';
import TransferButton from './buttons/transfer_button';
import TransferContainer from './transfer/transfer';
import UnmuteButton from './buttons/unmute_button';
import VisibilityControl from './visibility_control';
import { formatPhoneNumber } from 'models/phone_number';
import { MenuContainer, MenuItem } from 'components/lib/menu_deprecated';

const ActiveCallControls = createReactClass({
  propTypes: {
    activeCall: PropTypes.instanceOf(ActiveCall).isRequired,
    currentAgent: PropTypes.instanceOf(AgentProfile).isRequired,
    currentAgentParticipantStatus: PropTypes.string.isRequired,
    customerParticipantStatus: PropTypes.string,
    isCallQualityWarningEnabled: PropTypes.bool,
    isRecording: PropTypes.bool.isRequired,
    isResponsePending: PropTypes.bool,
    isHidden: PropTypes.bool,
    visibleObservers: PropTypes.array,
    // callbacks
    onCancelWarmTransfer: PropTypes.func.isRequired,
    onCloseTransferDisplay: PropTypes.func.isRequired,
    onEndPhoneCall: PropTypes.func.isRequired,
    onHoldPhoneCall: PropTypes.func.isRequired,
    onInterval: PropTypes.func.isRequired,
    onLeaveConference: PropTypes.func.isRequired,
    onMutePhoneCall: PropTypes.func.isRequired,
    onObserverHide: PropTypes.func.isRequired,
    onObserverShow: PropTypes.func.isRequired,
    onOpenTransferDisplay: PropTypes.func.isRequired,
  },

  componentDidMount() {
    const PRESENCE_INTERVAL_MS = 60 * 1000;

    this.intervalId = setInterval(() => this.props.onInterval(), PRESENCE_INTERVAL_MS);

    window.addEventListener('beforeunload', this.alertBeforeClosing);
  },

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.alertBeforeClosing);
    clearInterval(this.intervalId);
  },

  alertBeforeClosing(e) {
    e.preventDefault();
    // Only IE uses the custom string
    e.returnValue = 'Leaving Gladly will end your current call. Are you sure you want to leave?';
  },

  getInitialState() {
    return {
      displayEndCall: false,
      displayKeypad: false,
      displayConference: false,
      displayTransfer: false,
      displayParticipantList: false,
      hasCallOwnership: this.props.activeCall.hasCallOwnership(this.props.currentAgent.id),
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.activeCall.conversationItem.content.participantEvents.length !==
      nextProps.activeCall.conversationItem.content.participantEvents.length
    ) {
      this.setState({
        hasCallOwnership: nextProps.activeCall.hasCallOwnership(nextProps.currentAgent.id),
      });
    }
  },

  render() {
    const isCustomerVerified = this.props.activeCall.customer.isVerified();
    let classNames = classnames('activePhoneControls visible answered phoneControls-verified');

    return (
      <div className={classNames}>
        <div className="phoneControls-content answered">
          <ActiveCallPhoneNumberContainer
            conversationId={this.props.activeCall.conversationId}
            customerId={this.props.activeCall.customer.id}
            isCustomerUnverified={!isCustomerVerified}
            phoneNumber={formatPhoneNumber(this.props.activeCall.conversationItem.content.customerNumber)}
          />
          {this.renderPhoneControls(isCustomerVerified)}
        </div>
      </div>
    );
  },

  renderCallQualityWarning() {
    if (!this.props.isCallQualityWarningEnabled) {
      return null;
    }

    return (
      <CallQualityWarningContainer>
        <CallQualityWarning />
      </CallQualityWarningContainer>
    );
  },

  renderPhoneControls(isCustomerVerified) {
    if (this.isParticipantObserver()) {
      return this.renderObserverCallControls(isCustomerVerified);
    }
    if (this.props.currentAgent.isConfiguredForInteractiveVoice()) {
      return this.renderActiveCallControls(isCustomerVerified);
    }
    return this.renderNonInteractiveCallControls(isCustomerVerified);
  },

  renderNonInteractiveCallControls(isCustomerVerified) {
    return (
      <div className="phoneControls-container phoneControls-answered">
        {this.renderMarkCallCompleteButton(isCustomerVerified)}
      </div>
    );
  },

  renderObserverCallControls(isCustomerVerified) {
    return (
      <div
        className={classnames('phoneControls-container phoneControls-answered', {
          phoneControlsActionContainerDisplayed: this.isPhoneControlsActionContainerDisplayed(),
        })}
      >
        <div className="phoneControls-answered">
          <ul className="phoneControls-answered controls phoneControls-buttons">
            {this.renderVisibility()}
            {this.renderParticipantsButton()}
            {this.renderLeaveConference()}
            <Divider />
            {this.renderObserverStatusControls()}
          </ul>
        </div>
        {this.renderParticipantList(isCustomerVerified)}
      </div>
    );
  },

  renderActiveCallControls(isCustomerVerified) {
    let observers = this.props.visibleObservers.map(observer => <ObserverPill key={observer.id} {...observer} />);
    if (observers.length > 0) {
      observers.push(<div className="phoneControls-divider" key="ObserverPhoneControlDivider" />);
    }

    return (
      <>
        <div
          className={classnames('phoneControls-container phoneControls-answered', {
            'customer-verified': isCustomerVerified,
            phoneControlsActionContainerDisplayed:
              this.isPhoneControlsActionContainerDisplayed() && !this.state.displayEndCall,
          })}
        >
          <div className="phoneControls-answered">
            <ul className="phoneControls-answered controls phoneControls-buttons">
              {observers}
              {this.renderMute()}
              {this.renderHold()}
              {this.renderTransferButton()}
              {this.renderConferenceButton()}
              {this.renderKeypadButton()}
              {this.renderEndCall()}
              {this.renderRecordingControls()}
            </ul>
          </div>
          {this.renderKeypad(isCustomerVerified)}
          {this.renderConferenceContainer(isCustomerVerified)}
          {this.renderTransferContainer(isCustomerVerified)}
        </div>
        {this.renderCallQualityWarning()}
      </>
    );
  },

  renderMarkCallCompleteButton(isCustomerVerified) {
    return (
      <div className="phoneControls-answered">
        <div className="phoneControls-answered phoneControls-buttons">
          <Button
            buttonType={ButtonTypes.SECONDARY}
            className="phoneControls-markCompleteButton"
            onClick={this.endPhoneCall}
          >
            Mark call complete
          </Button>
        </div>
      </div>
    );
  },

  renderRecordingControls() {
    if (this.props.isRecording) {
      return [
        <div className="phoneControls-divider" key="PhoneControlDivider" />,
        <RecordingControls
          isCallEnding={this.isAgentDisconnecting() || this.isAgentTerminating() || this.isCustomerTerminating()}
          isTooltipDisabled={this.isPhoneControlsActionContainerDisplayed()}
          key="PhoneControlButton"
        />,
      ];
    }
  },

  toggleHold() {
    if (!this.props.isResponsePending) {
      let participantId = this.props.activeCall.conversationItem.content.customerNumber;
      const isCustomerOnHold = this.isCustomerOnHold();
      this.props.onHoldPhoneCall({ participantId, state: !isCustomerOnHold });
    }
  },

  toggleMute() {
    if (!this.props.isResponsePending) {
      this.setState({ displayKeypad: false });
      this.props.onMutePhoneCall(!this.isAgentMuted());
    }
  },

  renderKeypadButton() {
    let phoneCall = this.props.activeCall.conversationItem.content;
    const disabled =
      this.props.currentAgent.usesExternalHandset() ||
      this.isAgentMuted() ||
      (this.shouldDisableCallControl(phoneCall) && !phoneCall.isOutgoing());
    const classes = classnames('unstyled action phoneControls-keypad', { disabled });
    let tooltipDisabled = disabled || this.isPhoneControlsActionContainerDisplayed();
    return (
      <Tooltip
        className={'phoneControls-keypad-tooltip'}
        enabled={!tooltipDisabled}
        key={'phoneControls-keypad-tooltip'}
        message="Keypad"
        position="bottom"
      >
        <li
          className="phoneControls-button phoneControls-keypadButtonContainer"
          onClick={disabled ? _.noop : this.toggleKeypadDisplay}
        >
          <KeypadButton className={classes} />
        </li>
      </Tooltip>
    );
  },

  renderConferenceButton() {
    let phoneCall = this.props.activeCall.conversationItem.content;
    const disabled = this.props.activeCall.isTransferLive() || this.shouldDisableCallControl(phoneCall);
    const classes = classnames('unstyled action phoneControls-addCall', {
      disabled,
      'circleIcon-liveConference': PhoneCall.isLiveConference(phoneCall.participants),
    });
    let tooltipDisabled = disabled || this.isPhoneControlsActionContainerDisplayed();
    return (
      <Tooltip
        className={'phoneControls-addCall-tooltip'}
        enabled={!tooltipDisabled}
        key={'phoneControls-addCall-tooltip'}
        message="Conference"
        position="bottom"
      >
        <li
          className="phoneControls-button phoneControls-conferenceButtonContainer"
          onClick={disabled ? _.noop : this.toggleConferenceDisplay}
        >
          <ConferenceButton className={classes} />
        </li>
      </Tooltip>
    );
  },

  renderTransferButton() {
    let phoneCall = this.props.activeCall.conversationItem.content;
    const disabled = PhoneCall.isLiveConference(phoneCall.participants) || this.shouldDisableCallControl(phoneCall);
    const classes = classnames('unstyled action phoneControls-transferPhone', {
      disabled,
      'circleIcon-liveTransfer':
        (this.props.activeCall.transferee || this.props.activeCall.isTransferLive()) && !disabled,
    });
    let tooltipDisabled = disabled || this.isPhoneControlsActionContainerDisplayed();
    return (
      <Tooltip
        className={'phoneControls-transferPhone-tooltip'}
        enabled={!tooltipDisabled}
        key={'phoneControls-transferPhone-tooltip'}
        message="Transfer"
        position="bottom"
      >
        <li
          className="phoneControls-button phoneControls-transferPhoneButtonContainer"
          onClick={disabled ? _.noop : this.toggleTransferDisplay}
        >
          <TransferButton className={classes} />
        </li>
      </Tooltip>
    );
  },

  renderVisibility() {
    return <VisibilityControl isHidden={this.props.isHidden} onHide={this.hideObserver} onShow={this.showObserver} />;
  },

  renderParticipantsButton() {
    const phoneCall = this.props.activeCall.conversationItem.content;
    const classes = classnames('unstyled', 'action', 'phoneControls-participants');
    const joinedObservers = phoneCall.getJoinedObserverParticipantIds();
    const externalParticipants = phoneCall.getExternalActiveParticipantIds();
    const agentParticipants = phoneCall.getAgentParticipantIdsOnCall();
    const allParticipants = _.concat(joinedObservers, externalParticipants, agentParticipants);
    const participantsCount = 1 + allParticipants.length; // customer is always included in count
    return (
      <Tooltip
        className={'phoneControls-participants-tooltip'}
        enabled
        key={'phoneControls-participants-tooltip'}
        message={'Call participants'}
        position="bottom"
      >
        <li
          className="phoneControls-button phoneControls-callParticipantListContainer"
          onClick={this.toggleParticipantListDisplay}
        >
          <ParticipantsButton className={classes} numOfParticipants={participantsCount} />
        </li>
      </Tooltip>
    );
  },

  renderObserverStatusControls() {
    return (
      <CallCoachingPillsContainer
        conversationItemId={this.props.activeCall.conversationItem.id}
        customerId={this.props.activeCall.customer.id}
      />
    );
  },

  renderLeaveConference() {
    const tooltipMessage = 'Leave call';
    const classes = classnames('unstyled', 'action', 'phoneControls-leaveConference');
    let tooltipDisabled = this.isPhoneControlsActionContainerDisplayed();

    return (
      <Tooltip
        className={'phoneControls-leaveCall-tooltip'}
        enabled={!tooltipDisabled}
        key={'phoneControls-leaveCall-tooltip'}
        message={tooltipMessage}
        position="bottom"
      >
        <li className="phoneControls-button phoneControls-leaveConferenceContainer">
          <LeaveCallButton className={classes} onClick={this.leaveConference} />
        </li>
      </Tooltip>
    );
  },

  renderEndCall() {
    const tooltipMessage = 'End call';
    const classes = classnames('unstyled', 'action', 'phoneControls-endCall');
    let tooltipDisabled = this.isPhoneControlsActionContainerDisplayed();
    let callParticipants = this.props.activeCall.conversationItem.content.participants;
    let isDisabled = PhoneCall.isAnyExternalParticipantBeingDialed(callParticipants);
    if (PhoneCall.isLiveConference(callParticipants)) {
      return (
        <Tooltip
          className={'phoneControls-endCall-tooltip'}
          enabled={!tooltipDisabled}
          key={'phoneControls-endCall-tooltip'}
          message={tooltipMessage}
          position="bottom"
        >
          <li className="phoneControls-button phoneControls-endConferenceContainer" onClick={this.toggleEndCallDisplay}>
            <MenuContainer
              button={<LeaveCallButton className={classes} />}
              className="phoneControls-endConferenceMenu"
              onClose={this.closeEndCallDisplay}
            >
              <MenuItem isDisabled={isDisabled} onSelect={this.leaveConference}>
                Leave conference
              </MenuItem>
              <MenuItem onSelect={this.endPhoneCall}>End call for all</MenuItem>
            </MenuContainer>
          </li>
        </Tooltip>
      );
    }
    return (
      <Tooltip
        className={'phoneControls-endCall-tooltip'}
        enabled={!tooltipDisabled}
        key={'phoneControls-endCall-tooltip'}
        message={tooltipMessage}
        position="bottom"
      >
        <li className="phoneControls-button phoneControls-endConferenceContainer">
          <LeaveCallButton className={classes} onClick={this.endPhoneCall} />
        </li>
      </Tooltip>
    );
  },

  endPhoneCall() {
    if (this.isAgentDisconnecting()) {
      return;
    }
    if (this.state.hasCallOwnership) {
      this.props.onEndPhoneCall();
      return;
    }
    this.props.onCancelWarmTransfer({ agentId: this.props.currentAgent.id });
  },

  renderConferenceContainer(customerVerified) {
    if (this.state.displayConference) {
      return (
        <ConferenceContainer
          className={classnames({
            customerVerified,
            'customer-unverified': !customerVerified,
          })}
          onClose={this.closeConferenceDisplay}
        />
      );
    }
    return false;
  },

  renderKeypad(customerVerified) {
    if (this.state.displayKeypad) {
      return (
        <Keypad
          backToConferenceCallback={this.toggleConferenceDisplay}
          className={classnames({
            customerVerified,
            'customer-unverified': !customerVerified,
          })}
          isLiveConference={PhoneCall.isLiveConference(this.props.activeCall.conversationItem.content.participants)}
          onCloseCallback={this.closeKeypadDisplay}
        />
      );
    }
    return false;
  },

  renderTransferContainer(customerVerified) {
    if (this.state.displayTransfer) {
      return (
        <TransferContainer
          className={classnames({
            customerVerified,
            'customer-unverified': !customerVerified,
          })}
          onClose={this.closeTransferDisplay}
        />
      );
    }
    return null;
  },

  renderParticipantList(customerVerified) {
    if (this.state.displayParticipantList) {
      return <ParticipantListContainer onClose={this.closeCallParticipantListDisplay} />;
    }
    return null;
  },

  toggleConferenceDisplay() {
    this.setState({
      displayConference: !this.state.displayConference,
      displayEndCall: false,
      displayKeypad: false,
      displayParticipantList: false,
      displayTransfer: false,
    });
  },

  toggleEndCallDisplay() {
    this.setState({
      displayConference: false,
      displayEndCall: !this.state.displayEndCall,
      displayKeypad: false,
      displayParticipantList: false,
      displayTransfer: false,
    });
  },

  toggleKeypadDisplay() {
    this.setState({
      displayConference: false,
      displayEndCall: false,
      displayKeypad: !this.state.displayKeypad,
      displayParticipantList: false,
      displayTransfer: false,
    });
  },

  toggleTransferDisplay() {
    if (this.state.displayTransfer) {
      this.props.onCloseTransferDisplay();
    } else {
      this.props.onOpenTransferDisplay();
    }

    this.setState({
      displayConference: false,
      displayEndCall: false,
      displayKeypad: false,
      displayParticipantList: false,
      displayTransfer: !this.state.displayTransfer,
    });
  },

  toggleParticipantListDisplay() {
    this.setState({
      displayConference: false,
      displayEndCall: false,
      displayKeypad: false,
      displayParticipantList: !this.state.displayParticipantList,
      displayTransfer: false,
    });
  },

  hideObserver() {
    const customerId = this.props.activeCall.customer.id;
    const conversationItemId = this.props.activeCall.conversationItem.id;
    this.props.onObserverHide({ customerId, conversationItemId });
  },

  showObserver() {
    const customerId = this.props.activeCall.customer.id;
    const conversationItemId = this.props.activeCall.conversationItem.id;
    this.props.onObserverShow({ customerId, conversationItemId });
  },

  closeEndCallDisplay() {
    this.setState({ displayEndCall: false });
  },

  closeConferenceDisplay(evt) {
    if (this.isClickTargetButton(evt, 'phoneControls-conferenceButtonContainer')) {
      return;
    }
    this.setState({ displayConference: false });
  },

  closeKeypadDisplay(evt) {
    if (this.isClickTargetButton(evt, 'phoneControls-keypadButtonContainer')) {
      return;
    }
    this.setState({ displayKeypad: false });
  },

  closeTransferDisplay(evt) {
    if (this.isClickTargetButton(evt, 'phoneControls-transferPhoneButtonContainer')) {
      return;
    }

    this.props.onCloseTransferDisplay();
    this.setState({ displayTransfer: false });
  },

  closeCallParticipantListDisplay(evt) {
    if (this.isClickTargetButton(evt, 'phoneControls-callParticipantListContainer')) {
      return;
    }
    this.setState({ displayParticipantList: false });
  },

  renderHold() {
    let phoneCall = this.props.activeCall.conversationItem.content;
    const hasCustomerHungup = this.props.customerParticipantStatus === PhoneCall.ParticipantStatus.HUNG_UP;
    const disabled = hasCustomerHungup || this.shouldDisableCallControl(phoneCall);
    const isCustomerOnHold = this.isCustomerOnHold();
    const holdClasses = classnames('unstyled', 'phoneControls-holdPhone', {
      'activeCall-holdRequested': this.isHoldRequested(),
      disabled,
      'circleIcon-pendingAnimation activeCall-hold': isCustomerOnHold && !disabled,
    });
    const tooltipMessage = isCustomerOnHold ? 'Take customer off hold' : 'Put customer on hold';
    let tooltipDisabled = disabled || this.isPhoneControlsActionContainerDisplayed();
    return (
      <Tooltip
        className={'phoneControls-holdPhone-tooltip'}
        enabled={!tooltipDisabled}
        key={'phoneControls-holdPhone-tooltip'}
        message={tooltipMessage}
        position="bottom"
      >
        <li className="phoneControls-button phoneControls-holdContainer" onClick={disabled ? _.noop : this.toggleHold}>
          <HoldButton className={holdClasses} />
        </li>
      </Tooltip>
    );
  },

  renderMute() {
    let phoneCall = this.props.activeCall.conversationItem.content;
    const disabled = this.shouldDisableCallControl(phoneCall);
    const isAgentMuted = this.isAgentMuted();
    const muteClasses = classnames('unstyled', 'phoneControls-mutePhone', {
      'circleIcon-deactivated': isAgentMuted,
      disabled,
      'circleIcon-pendingAnimation': this.isMuteRequested(),
    });
    const tooltipMessage = isAgentMuted ? 'Unmute yourself' : 'Mute yourself';
    let tooltipDisabled = disabled || this.isPhoneControlsActionContainerDisplayed();
    return (
      <Tooltip
        className={'phoneControls-mutePhone-tooltip'}
        enabled={!tooltipDisabled}
        key={'phoneControls-mutePhone-tooltip'}
        message={tooltipMessage}
        position="bottom"
      >
        <li className="phoneControls-button phoneControls-muteContainer" onClick={disabled ? _.noop : this.toggleMute}>
          {isAgentMuted ? <MuteButton className={muteClasses} /> : <UnmuteButton className={muteClasses} />}
        </li>
      </Tooltip>
    );
  },

  leaveConference() {
    this.props.onLeaveConference(this.props.currentAgent.id);
  },

  isParticipantObserver() {
    return this.props.activeCall.isParticipantObserver(this.props.currentAgent.id);
  },

  isAgentDisconnecting() {
    return this.props.currentAgentParticipantStatus === PhoneCall.ParticipantStatus.DISCONNECTING;
  },

  isAgentMuted() {
    return this.props.currentAgentParticipantStatus === PhoneCall.ParticipantStatus.MUTED;
  },

  isAgentTerminating() {
    return this.props.currentAgentParticipantStatus === PhoneCall.ParticipantStatus.TERMINATING;
  },

  isCustomerOnHold() {
    return this.props.customerParticipantStatus === PhoneCall.ParticipantStatus.ON_HOLD;
  },

  isCustomerTerminating() {
    return this.props.customerParticipantStatus === PhoneCall.ParticipantStatus.TERMINATING;
  },

  isHoldRequested() {
    return this.props.customerParticipantStatus === PhoneCall.ParticipantStatus.HOLD_REQUESTED;
  },

  isMuteRequested() {
    return this.props.currentAgentParticipantStatus === PhoneCall.ParticipantStatus.MUTE_REQUESTED;
  },

  isTransferRequested() {
    return this.props.currentAgentParticipantStatus === PhoneCall.ParticipantStatus.TRANSFER_REQUESTED;
  },

  isPhoneControlsActionContainerDisplayed() {
    return (
      this.state.displayConference ||
      this.state.displayEndCall ||
      this.state.displayKeypad ||
      this.state.displayTransfer ||
      this.state.displayParticipantList
    );
  },

  isClickTargetButton(evt, buttonClass) {
    if (!evt) {
      return;
    }
    let target = evt.target;
    let result = false;
    while (target) {
      if (target && target.classList && target.classList.contains(buttonClass)) {
        result = true;
        break;
      }
      target = target.parentNode;
    }
    return result;
  },

  shouldDisableCallControl(phoneCall) {
    return (
      !phoneCall.isLive() ||
      this.isTransferRequested() ||
      !this.state.hasCallOwnership ||
      this.isAgentDisconnecting() ||
      this.isAgentTerminating() ||
      this.isCustomerTerminating()
    );
  },
});

const Divider = styled.div`
  border-right: 1px solid ${p => p.theme.colors.gray500};
  height: ${p => p.theme.lineHeight.controls};
  margin-left: 4px;
  margin-right: 12px;
`;

const CallQualityWarningContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 10px;
  width: 111px;
`;

export default ActiveCallControls;
