import adminRoutes from './admin_routes';
import answerPerformanceRoutes from './answer_performance_routes';
import answersPreviewRoutes from './answers_preview_routes';
import apiTokensRoutes from './api_tokens_routes';
import Boot from 'models/location/boot';
import customerRoutes from './customer_routes';
import customReportingRoutes from './custom_reporting_routes';
import datasetsRoutes from './datasets_routes';
import DisconnectCheckBeforeUnloadEventHandler from 'actions/health/disconnect_check_before_unload_event_handler';
import homeRoutes from './home_routes';
import integrationsRoutes from './integrations_routes';
import kbAdminRoutes from './kb_admin_routes';
import keywordGroupRoutes from './keyword_group_routes';
import liveboardsRoutes from './liveboards_routes';
import recommendedAnswersRoutes from './recommended_answers_routes';
import { registerUrlConverter } from './location_url';
import reportRoutes from './report_routes';
import searchRoutes from './search_routes';
import StaticUrlConverter from './url_converters/static_url_converter';
import userRoutes from './user_routes';
import webhookRoutes from './webhooks_routes';

export const SEARCH = '/search';
export const LIVEBOARDS = '/liveboards';
export const AGENT_LIVEBOARDS = `${LIVEBOARDS}/agents`;

registerUrlConverter(Boot, StaticUrlConverter.create('/'));

export default function(router, routeParams) {
  return {
    before() {
      let { runnerContext } = routeParams;

      // check that there are no connection issues before
      // allowing the route to unload
      let handler = new DisconnectCheckBeforeUnloadEventHandler(runnerContext);
      router.onBeforeUnload(handler.handleBeforeUnload);
    },
    '/admin': adminRoutes(router, routeParams),
    '/answer-performance': answerPerformanceRoutes(router, routeParams),
    '/answers-preview': answersPreviewRoutes(router, routeParams),
    '/api-tokens': apiTokensRoutes(router, routeParams),
    '/customer': customerRoutes(router, routeParams),
    '/customreporting': customReportingRoutes(router, routeParams),
    '/datasets': datasetsRoutes(router, routeParams),
    '/home': homeRoutes(router, routeParams),
    '/integrations': integrationsRoutes(router, routeParams),
    '/kb-admin': kbAdminRoutes(router, routeParams),
    '/keyword-groups': keywordGroupRoutes(router, routeParams),
    [LIVEBOARDS]: liveboardsRoutes(router, routeParams),
    '/recommended-answers': recommendedAnswersRoutes(router, routeParams),
    '/reports': reportRoutes(router, routeParams),
    [SEARCH]: searchRoutes(router, routeParams),
    '/user': userRoutes(router, routeParams),
    '/webhooks': webhookRoutes(router, routeParams),
  };
}
