import AnswerPerformanceView from 'models/location/answer_performance_view';
import changeCurrentLocation from 'actions/lib/change_current_location';

export default class OpenAnswerPerformance {
  constructor(context) {
    this.context = context;
  }

  run({ audienceId, dateRange }) {
    changeCurrentLocation(this.context, AnswerPerformanceView.create({ audienceId, dateRange }));
    this.context.stores.answerPerformances.setLoading();
    this.context.gateways.answerPerformance.fetchAll({ audienceId, dateRange });
  }
}
