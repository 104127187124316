import AnswerPerformanceView from 'models/location/answer_performance_view';

export default class FilterAnswerPerformance {
  constructor(context) {
    this.context = context;
  }

  run({ audienceId, dateRange }) {
    this.context.router.navigateTo(AnswerPerformanceView.create({ audienceId, dateRange }));
  }
}
