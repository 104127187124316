import createLocationModel, { prop } from './lib/create_location_model';

export default createLocationModel({
  modelName: 'AnswerPerformanceView',
  properties: {
    audienceId: String,
    dateRange: prop(String).default('1d'),
  },

  statics: {
    breadcrumb: 'Answer Performance',
  },
});
