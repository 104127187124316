import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import styled, { css } from 'styled-components';
import { find } from 'lodash';

import AnswerPerformanceView from 'models/location/answer_performance_view';
import Inbox from 'models/location/inbox';
import KbAdmin from 'models/location/kb_admin';
import Link from 'components/common/link';
import LinkOutStroke from 'components/common/icons/stroke/link-out-stroke';
import Liveboards from 'models/location/liveboards';
import NavigateTo from 'actions/current_location/navigate_to';
import Reports from 'models/location/reports';
import { getLocationUrl } from 'scripts/adapters/routes/location_url';
import { HotkeysShownContext } from 'components/contexts/hotkeys';
import { useExecuteAction } from 'components/hooks/connect_hooks';
import useIsFeatureEnabled from 'components/hooks/use_is_feature_enabled';

export default function NavigationMenu({ currentAgentRoles, onClose }) {
  const isFeatureEnabled = useIsFeatureEnabled();
  const { dispatchHotkeysShown } = useContext(HotkeysShownContext);
  const showHotkeys = useCallback(() => dispatchHotkeysShown('on'), [dispatchHotkeysShown]);
  const managerRoles = ['compliance-admin', 'system-admin', 'team-manager'];

  // satisfies at least one role
  const satisfiesRoles = roles => {
    if (typeof roles === 'string') roles = [roles];
    return roles.some(role => currentAgentRoles.includes(role));
  };

  // satisfies at least one feature
  const satisfiesFeatures = features => {
    if (typeof features === 'string') features = [features];
    return !!find(features, feature => isFeatureEnabled(feature));
  };

  return (
    <div className={`navigationMenu navigationMenuV2`}>
      <StyledLine />
      {satisfiesFeatures('internalAgentActions') && (
        <MenuOption locationModel={Inbox} locationPath="" onClick={onClose} title="Home" />
      )}
      {satisfiesRoles(managerRoles) && satisfiesFeatures('sidekickV2') && (
        <StyledMenuItem>
          <StyledLink
            className="navigationMenu-option"
            href="/sidekick"
            onClick={evt => {
              evt.preventDefault();
              window.location.assign('/sidekick');
            }}
          >
            Sidekick<Beta>beta</Beta>
          </StyledLink>
        </StyledMenuItem>
      )}
      {satisfiesFeatures(['kbManagement', 'internalAgentActions']) && (
        <MenuOption locationModel={KbAdmin} onClick={onClose} title="Answers" />
      )}
      {satisfiesRoles(managerRoles) && satisfiesFeatures('answerPerformance') && (
        <MenuOption locationModel={AnswerPerformanceView} onClick={onClose} title="Journeys" />
      )}
      {satisfiesFeatures('viewReports') && <MenuOption locationModel={Reports} onClick={onClose} title="Reports" />}
      {satisfiesFeatures('viewLiveboards') && (
        <MenuOption locationModel={Liveboards} onClick={onClose} title="Liveboards" />
      )}
      <StyledLine />
      {satisfiesFeatures(['adminSettings', 'devManagement', 'debuggingView', 'kbManagement']) && (
        <StyledMenuItem data-aid="settings-menu">
          <StyledLink
            className="navigationMenu-option"
            href="/admin/home"
            onClick={evt => {
              evt.preventDefault();
              window.location.assign('/admin/home');
            }}
          >
            Settings
          </StyledLink>
        </StyledMenuItem>
      )}
      <StyledLine />
      <StyledMenuItem>
        <StyledExternalLink
          className="navigationMenu-option"
          data-aid="navigationMenu-new-features"
          href="https://connect.gladly.com/docs/help-documentation/release-notes/"
          tabIndex="-1"
          target="release-notes"
        >
          What's new in Gladly
          <StyledLinkOutStroke />
        </StyledExternalLink>
      </StyledMenuItem>
      <StyledMenuItem>
        <StyledExternalLink
          className="navigationMenu-option"
          data-aid="navigationMenu-help-link"
          href="https://connect.gladly.com/docs/help-documentation/"
          tabIndex="-1"
          target="help"
        >
          Help Docs
          <StyledLinkOutStroke />
        </StyledExternalLink>
      </StyledMenuItem>
      {satisfiesFeatures('contactSupport') && (
        <StyledMenuItem data-aid="contact-support-menu">
          <StyledExternalLink
            className="navigationMenu-option"
            data-aid="navigationMenu-help-link"
            href="/support.html"
            tabIndex="-1"
            target="support"
          >
            Contact Support
            <StyledLinkOutStroke />
          </StyledExternalLink>
        </StyledMenuItem>
      )}
      <StyledLine />
      <StyledMenuItem>
        <StyledLink
          className="navigationMenu-option"
          data-aid="navigationMenu-shortcuts-link"
          onClick={showHotkeys}
          tabIndex="-1"
        >
          Keyboard Shortcuts
        </StyledLink>
      </StyledMenuItem>
      <PrivacyPolicySection>
        <PrivacyPolicyLink
          data-aid="navigationMenu-privacy-policy-link"
          href="https://www.gladly.com/privacy-policy/"
          rel="noopener noreferrer"
          tabIndex="-1"
          target="_blank"
        >
          Privacy Policy
        </PrivacyPolicyLink>
      </PrivacyPolicySection>
    </div>
  );
}

NavigationMenu.propTypes = {
  currentAgentRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

export const MenuOption = ({ locationModel, onClick, title }) => {
  const executeAction = useExecuteAction();
  const handleClick = useCallback(
    evt => {
      evt.preventDefault();
      executeAction(NavigateTo, locationModel.create());
      onClick();
    },
    [executeAction, locationModel, onClick]
  );

  const menuPath = getLocationUrl(locationModel.create());
  const currentLocation = window.location.pathname.split('/')[1];
  const isActiveItem = menuPath.split('/')[1] === currentLocation;
  return (
    <StyledMenuItem isActive={isActiveItem}>
      <StyledLink className="navigationMenu-option" href={menuPath} onClick={handleClick}>
        {title}
      </StyledLink>
    </StyledMenuItem>
  );
};

MenuOption.propTypes = {
  locationModel: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const ActiveItem = css`
  background-color: ${p => p.theme.colors.gray200};
  font-weight: bold;
  &:before {
    background-color: ${p => p.theme.colors.green500};
    bottom: 0;
    content: '';
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    width: 4px;
  }
`;

const StyledLinkOutStroke = styled(LinkOutStroke)`
  height: 14px;
  width: 14px;
  margin-left: 4px;
`;

export const StyledLink = styled(Link)`
  color: ${p => p.theme.colors.black};
  align-items: center;
  display: flex;
  height: 32px;
  padding: 6px 24px;
  cursor: pointer;
  &:hover {
    color: ${p => p.theme.colors.black};
    text-decoration: none;
  }
`;

const Beta = styled.span`
  color: ${p => p.theme.colors.gray700};
  padding-left: ${p => p.theme.spacing.medium};
`;

const StyledLine = styled.div`
  height: 1px;
  background-color: ${p => p.theme.colors.gray300};
  margin: 2px 0;

  & + & {
    display: none;
  }
`;

const StyledMenuItem = styled.div`
  cursor: pointer;
  padding: 0;
  position: relative;
  &:hover {
    background-color: ${p => p.theme.colors.green100};
  }
  ${p => p.isActive && ActiveItem}
`;

export const StyledExternalLink = styled.a`
  color: ${p => p.theme.colors.black};
  cursor: pointer;
  &:hover {
    color: ${p => p.theme.colors.green400};
    text-decoration: none;
  }
`;

const PrivacyPolicySection = styled.div`
  bottom: 10px;
  left: 0;
  width: 100%;
  padding: 8px 0;
  position: absolute;
`;

const PrivacyPolicyLink = styled.a`
  color: ${p => p.theme.colors.gray800};
  display: block;
  width: 100%;
  height: 32px;
  padding: 6px 24px;
  cursor: pointer;
  &:hover {
    color: ${p => p.theme.colors.gray800};
    text-decoration: none;
    background-color: ${p => p.theme.colors.green100};
  }
`;
