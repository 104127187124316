import DtoConverter from 'scripts/application/dto_converters/answer_performance_converter';
import qconsole from 'scripts/lib/qconsole';

export default class AnswerPerformanceGatewayObserver {
  constructor(context) {
    this.context = context;
  }

  get store() {
    return this.context.stores.answerPerformances;
  }

  onFetchAllSuccess(responseDto) {
    this.store.resetLoading();
    if (responseDto) {
      this.store.set(responseDto.map(dto => DtoConverter.fromDto(dto)));
    }
  }

  onFetchAllError(error) {
    this.store.resetLoading();
    qconsole.log(`Could not fetch answer performance data: ${error}`);
  }
}
