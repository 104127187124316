import _ from 'lodash';
import moment from 'moment';

import Communicator from 'models/communicator';
import Factory from 'factories/all';
import qconsole from 'scripts/lib/qconsole';
import RingIterator from './ring_iterator';

export default class CommunicationSimulator {
  constructor({ attribute, createItem, orgId, publisher, services }, database) {
    this.attribute = attribute;
    this.createItem = createItem;
    this.orgId = orgId;
    this.database = database;
    this.publisher = publisher;
    this.services = services;
  }

  simulateNext() {
    let nextCommunication = this._getNextCommunication();
    if (nextCommunication) {
      this.simulate(nextCommunication);
    }
  }

  simulate(communication) {
    let customer = this._getCustomer(communication);
    if (!customer) {
      return;
    }

    let assignee = this._createAssignee(communication);

    let contextConversationItem = this._createContextItem(communication, customer.id);
    let communicationConversationItem = this.createItem(communication, customer.id);
    let routingConversationItem = this._createRoutingItem({ assignee, customerId: customer.id });
    let conversationItems = _.compact([
      contextConversationItem,
      communicationConversationItem,
      routingConversationItem,
    ]);

    let conversation = this._findConversation(communication, customer);
    if (conversation) {
      this.services.conversations.update(this.orgId, customer.id, conversation.id, {
        correlationId: 'simulated-update',
        payload: { ...conversation, newConversationItems: conversationItems },
      });
    } else {
      conversation = this._createConversation(assignee, customer);
      if (!conversation) {
        return;
      }
      if (!communicationConversationItem.conversationId) {
        communicationConversationItem.conversationId = conversation.id;
      }

      this.services.conversations.add(this.orgId, customer.id, {
        correlationId: 'simulated-add',
        payload: { ...conversation, newConversationItems: conversationItems },
      });
    }

    this.publisher.publish(customer, conversation, communicationConversationItem);
  }

  _getCustomer({ customerId }) {
    let customer = _.find(this.database.get().customers, { id: customerId });
    if (!customer) {
      qconsole.warn(`Unknown customer id ${customerId} in ${this.attribute}`);
    }
    return customer;
  }

  _createAssignee(communication) {
    return Factory.build('Assignee', {
      agentId: this.database.get().currentAgent.id,
      routingGroupId: communication.routingGroupId,
    });
  }

  get iterator() {
    return this._iterator || (this._iterator = new RingIterator(this.communications));
  }

  get communications() {
    let communications = this.database.get()[this.attribute];
    return communications || qconsole.warn(`No ${this.attribute} defined`);
  }

  _findConversation(customer, communication) {
    return undefined;
  }

  _createConversation(assignee, customer) {
    return Factory.build('ConversationWithDefaults', { assignee });
  }

  _getNextCommunication() {
    return this.communications && this.iterator.getNext();
  }

  _createContextItem(communication, customerId) {
    if (!communication.context) {
      return undefined;
    }

    return Factory.build('ConversationItemWithDefaults', {
      timestamp: moment()
        .subtract(5, 'minutes')
        .format(),
      initiator: {
        type: Communicator.CUSTOMER,
        id: customerId,
      },
      content: communication.context,
    });
  }

  _createRoutingItem() {
    return null;
  }

  _getRoutingItemAttrs({ assignee, customerId, channel }) {
    return {
      timestamp: new Date().toISOString(),
      initiator: { id: customerId, type: Communicator.CUSTOMER },
      content: {
        assignee,
        assigneeWas: { routingGroupId: assignee.routingGroupId },
        detail: { channel },
      },
    };
  }
}
