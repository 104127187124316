import React from 'react';

import { H1 } from 'components/common/headers';
import PageLayout from 'components/page_layout';
import styled from 'styled-components';

export default function RecommendedAnswers() {
  return (
    <PageLayout className="recommended-answers-pageWrapper" id="recommended-answers">
      <RecommendedAnswersPage>
        <H1>Recommended Answers</H1>
      </RecommendedAnswersPage>
    </PageLayout>
  );
}

const RecommendedAnswersPage = styled.div`
  margin: 40px 64px;
`;
