import classnames from 'classnames';
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import Err from 'models/err';
import { filterPasswordErrors, hasNotAllowedWithSSOError, isUnauthorized } from 'components/user/auth_errors';
import PasswordField from './lib/password_field';
import PasswordHints from './lib/password_hints';
import ProgressBar from 'components/common/progress_bar';
import UserAuthLayout from './lib/user_auth_layout';
import UserButton, { UserActionButton } from 'components/user/lib/user_button';
import UserCredential from 'models/user_credential';
import UserCredentialType from 'models/user_credential_type';

const UserResetPassword = createReactClass({
  propTypes: {
    currentAgentId: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(PropTypes.instanceOf(Err)).isRequired,
    isPasswordResetPending: PropTypes.bool.isRequired,
    onNavigateToLogin: PropTypes.func.isRequired,
    onNavigateToForgotPassword: PropTypes.func.isRequired,
    onResetPassword: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      hasPasswordChangedSinceLastSubmit: false,
      isSubmitted: false,
      password: '',
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isPasswordResetPending && !nextProps.isPasswordResetPending && nextProps.errors.length) {
      this.setState({ isSubmitted: false });
    }
  },

  render() {
    if (hasNotAllowedWithSSOError(this.props.errors)) {
      return <NotAllowedWithSSO loginUrl={this.props.errors[0].meta?.gladlyUrl ?? '/'} />;
    }

    if (isUnauthorized(this.props.errors)) {
      return this.renderUserUnauthorized();
    }

    if (this.state.isSubmitted && !this.props.isPasswordResetPending && !this.props.errors.length) {
      return this.renderPasswordResetComplete();
    }

    return this.renderPasswordResetForm();
  },

  renderUserUnauthorized() {
    return (
      <UserAuthLayout
        className="userResetPasswordForm-layout userResetPassword-failure"
        message={
          <div>
            <p className="userResetPassword-failure-message">The password reset link is invalid</p>
            <p className="userResetPassword-failure-message">or has expired.</p>
          </div>
        }
        title="Password Reset Failed"
      >
        <div className="userAuthLayout-proceedLinkContainer form-group">
          <UserButton
            className="userAuthLayout-button userAuthLayout-button-navigateToForgotPassword"
            onClick={this.props.onNavigateToForgotPassword}
          >
            Reset password again
          </UserButton>
        </div>
      </UserAuthLayout>
    );
  },

  renderPasswordResetComplete() {
    return (
      <UserAuthLayout
        className="userResetPasswordForm-layout userResetPassword-success"
        displayLogo
        image={<div className="userResetPassword-completeImage" />}
        message={
          <div>
            <p>Success! Your password reset is complete.</p>
            <p>Please log in with your new password.</p>
          </div>
        }
        title="Your password has changed"
      >
        <div className="userAuthLayout-proceedLinkContainer">
          <div className="form-group">
            <UserButton
              className="userAuthLayout-button userAuthLayout-button-navigateToLogin"
              onClick={this.props.onNavigateToLogin}
            >
              Sign In
            </UserButton>
          </div>
        </div>
      </UserAuthLayout>
    );
  },

  renderPasswordResetForm() {
    const passwordContainerClassNames = classnames('userAuthLayout-passwordContainer', {
      'userAuthLayout-passwordContainer-hasSubmitErrors': this.getSubmitErrors().length > 0,
    });

    return (
      <UserAuthLayout
        className="userResetPasswordForm-layout"
        displayLogo
        image={<div className="userResetPassword-resetImage" />}
        onLogoClick={this.props.onNavigateToLogin}
        title="Reset your password"
      >
        <ProgressBar isVisible={!!this.props.isPasswordResetPending} />
        <div className="userAuthLayout-formContainer userResetPassword-formContainer">
          <form className="login-form" onSubmit={this.handleResetPassword}>
            <div className="userAuthLayout-formElementsContainer">
              <div className="userAuthLayout-formElements">
                <div className="userResetPassword-message">Enter your new password</div>
                <div className={passwordContainerClassNames}>
                  <PasswordField
                    autoFocus
                    className="userAuthLayout-form-input userAuthLayout-passwordInput"
                    disabled={!!this.props.isPasswordResetPending}
                    onBlur={this.handleBlur}
                    onChange={this.handlePasswordChange}
                    onFocus={this.handleFocus}
                    onSubmit={this.handleResetPassword}
                    value={this.state.password}
                  />
                </div>
                <div className="form-group">{this.renderCompleteResetButton()}</div>
              </div>
              <PasswordHints
                errors={getPasswordValidationErrors(this.state.password).concat(this.getSubmitErrors())}
                hasDifferentPasswordRequirement
                password={this.state.password}
              />
            </div>
          </form>
        </div>
      </UserAuthLayout>
    );
  },

  renderCompleteResetButton() {
    const disabled = this.isSubmitDisabled();
    let classNames = classnames('userAuthLayout-form-button userAuthLayout-form-completePasswordReset', {
      disabled,
    });
    return (
      <UserActionButton className={classNames} disabled={disabled} onClick={this.handleResetPassword}>
        Change Password
      </UserActionButton>
    );
  },

  handlePasswordChange(password) {
    this.setState({ password, hasPasswordChangedSinceLastSubmit: true });
  },

  handleResetPassword(ev) {
    ev.preventDefault();

    if (!this.isSubmitDisabled()) {
      this.props.onResetPassword({ id: this.props.currentAgentId, password: this.state.password });
      this.setState({ isSubmitted: true, hasPasswordChangedSinceLastSubmit: false });
    }
  },

  getSubmitErrors() {
    return this.state.hasPasswordChangedSinceLastSubmit ? [] : filterPasswordErrors(this.props.errors);
  },

  isSubmitDisabled() {
    return (
      this.props.isPasswordResetPending ||
      !this.state.password ||
      getPasswordValidationErrors(this.state.password).length > 0 ||
      (!this.state.hasPasswordChangedSinceLastSubmit && this.props.errors.length > 0)
    );
  },
});

export function getPasswordValidationErrors(password) {
  return UserCredential.getValidationErrors(UserCredentialType.RESET_PASSWORD_TOKEN, { password }).concat(
    UserCredential.trimAndValidatePassword(password)
  );
}

const NotAllowedWithSSO = function({ loginUrl }) {
  return (
    <UserAuthLayout
      className="userResetPasswordForm-layout userResetPassword-failure"
      message={
        <div>
          <p className="userResetPassword-failure-message">Single sign-on is required for your organization.</p>
        </div>
      }
      title="Password Reset Not Allowed"
    >
      <div className="userAuthLayout-formContainer userActivation-formContainer">
        <form action={loginUrl} className="form-group">
          <UserButton
            className="form-control btn userAuthLayout-button btn-default userAuthLayout-button-navigateToLogin"
            data-aid="userAuthLayout-form-sso"
          >
            Use Single Sign-On
          </UserButton>
        </form>
      </div>
    </UserAuthLayout>
  );
};

NotAllowedWithSSO.propTypes = {
  loginUrl: PropTypes.string.isRequired,
};

export default UserResetPassword;
