import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import Err from 'models/err';
import { filterPasswordErrors, hasNotAllowedWithSSOError, isUnauthorized } from 'components/user/auth_errors';
import NameHints from './lib/name_hints';
import PasswordField from './lib/password_field';
import PasswordHints from './lib/password_hints';
import ProgressBar from 'components/common/progress_bar';
import StackContainer from 'components/common/containers/stack_container';
import UserAuthLayout from './lib/user_auth_layout';
import UserButton, { UserActionButton } from 'components/user/lib/user_button';

const UserActivation = createReactClass({
  propTypes: {
    currentAgentId: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(PropTypes.instanceOf(Err)).isRequired,
    isResponsePending: PropTypes.bool.isRequired,
    onActivate: PropTypes.func.isRequired,
    onAttributesChange: PropTypes.func.isRequired,
    onContinue: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      name: '',
      password: '',
      userActivated: false,
    };
  },

  render() {
    if (hasNotAllowedWithSSOError(this.props.errors)) {
      return <NotAllowedWithSSO loginUrl={this.props.errors[0].meta?.gladlyUrl ?? '/'} />;
    }

    if (isUnauthorized(this.props.errors)) {
      return this.renderUserUnauthorized();
    }

    if (this.props.isResponsePending && this.props.errors.length === 0) {
      return this.renderLoadingState();
    }

    if (this.state.userActivated) {
      return this.renderUserActivated();
    }

    return this.renderActivationForm();
  },

  renderLoadingState() {
    return (
      <UserAuthLayout className="userActivation-layout" message="We are setting up your account" title="One moment">
        <ProgressBar isVisible="true" />
      </UserAuthLayout>
    );
  },

  renderUserUnauthorized() {
    return (
      <UserAuthLayout
        className="userActivation-layout userActivation-failure"
        message={
          'Your activation code is invalid or has expired.\nPlease contact your System Administrator to request a new activation code.'
        }
        title="Account Activation Failed"
      >
        {this.renderContinueButton()}
      </UserAuthLayout>
    );
  },

  renderUserActivated() {
    return (
      <UserAuthLayout
        className="userActivation-success"
        displayLogo
        image={<div className="userActivation-image" />}
        message="'Continue' and log into Gladly using your credentials."
        title="Account Activated"
      >
        {this.renderContinueButton()}
      </UserAuthLayout>
    );
  },

  renderContinueButton() {
    return (
      <div className="userAuthLayout-proceedLinkContainer">
        <div className="form-group">
          <UserButton
            className="form-control btn userAuthLayout-button btn-default userAuthLayout-button-navigateToLogin"
            onClick={this.handleContinueClick}
          >
            Continue
          </UserButton>
        </div>
      </div>
    );
  },

  renderActivationForm() {
    return (
      <UserAuthLayout
        className="userActivation-layout"
        displayLogo
        image={<div className="userActivation-image" />}
        title="Account Activation"
      >
        <div className="userAuthLayout-formContainer userActivation-formContainer">
          <form className="login-form" onSubmit={this.activateUser}>
            <div className="userAuthLayout-formElementsContainer">
              <div className="userAuthLayout-formElements">
                <div className="userActivation-message">Enter your name and create a password</div>
                <div>
                  <input
                    autoFocus
                    className="userAuthLayout-form-input name-control userAuthLayout-activation-name"
                    onChange={this.onNameChange}
                    placeholder="Full Name"
                  />
                </div>
                <div className="userAuthLayout-passwordContainer">
                  <PasswordField
                    className="userAuthLayout-form-input userAuthLayout-passwordInput"
                    onChange={this.onPasswordChange}
                    onSubmit={this.activateUser}
                    value={this.state.password}
                  />
                </div>
                <div className="form-group">{this.renderCompleteActivationButton()}</div>
              </div>
              <div>
                <StackContainer inset="none" stack="medium">
                  <NameHints errors={this.getNameErrorCodes()} name={this.state.name} />
                </StackContainer>
                <StackContainer inset="none" stack="none">
                  <PasswordHints errors={this.getPasswordErrors()} password={this.state.password} />
                </StackContainer>
              </div>
            </div>
          </form>
        </div>
      </UserAuthLayout>
    );
  },

  getNameErrorCodes() {
    return this.props.errors.filter(e => e.attr === 'name').map(e => e.code);
  },

  getPasswordErrors() {
    return filterPasswordErrors(this.props.errors);
  },

  hasValidUserAttributes() {
    return (
      this.state.name &&
      this.state.password &&
      this.getNameErrorCodes().length === 0 &&
      this.getPasswordErrors().length === 0
    );
  },

  activateUser(ev) {
    ev.preventDefault();
    if (this.hasValidUserAttributes()) {
      this.props.onActivate({
        id: this.props.currentAgentId,
        name: this.state.name,
        password: this.state.password,
      });
      this.setState({ userActivated: true });
    }
  },

  handleContinueClick(ev) {
    ev.preventDefault();
    this.setState({ userActivated: false });
    this.props.onContinue();
  },

  renderCompleteActivationButton() {
    return (
      <UserActionButton
        className="form-control userAuthLayout-form-button userAuthLayout-form-completeActivation"
        disabled={!this.hasValidUserAttributes()}
        onClick={this.activateUser}
      >
        Complete Activation
      </UserActionButton>
    );
  },

  onPasswordChange(password) {
    this.setState({ password, userActivated: false });
    this.props.onAttributesChange({ name: this.state.name, password });
  },

  onNameChange(ev) {
    let name = ev.target.value;
    this.setState({ name, userActivated: false });
    this.props.onAttributesChange({ name, password: this.state.password });
  },
});

const NotAllowedWithSSO = function({ loginUrl }) {
  return (
    <UserAuthLayout
      className="userActivation-layout userActivation-failuree"
      message={
        <div>
          <p className="userResetPassword-failure-message">Single sign-on is required for your organization.</p>
        </div>
      }
      title="Password Creation Not Allowed"
    >
      <div className="userAuthLayout-formContainer userActivation-formContainer">
        <form action={loginUrl} className="form-group">
          <UserButton
            className="form-control btn userAuthLayout-button btn-default userAuthLayout-button-navigateToLogin"
            data-aid="userAuthLayout-form-sso"
          >
            Use Single Sign-On
          </UserButton>
        </form>
      </div>
    </UserAuthLayout>
  );
};

NotAllowedWithSSO.propTypes = {
  loginUrl: PropTypes.string.isRequired,
};

export default UserActivation;
