import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class AnswerPerformanceService {
  constructor(getDatabase) {
    this.getDatabase = getDatabase;
  }

  findAnswerPerformance(attrs, callback, path, { orgId }, query) {
    const answerPerformanceDetails = this.getDatabase(orgId).answerPerformanceDetails || {
      '1d': [],
      '7d': [],
      '30d': [],
      '90d': [],
    };

    let response = answerPerformanceDetails[query['f.t']];
    if (query['f.aid']) {
      response = response.filter(detail => detail.audienceIds?.indexOf(query['f.aid']) !== -1);
    }

    callback(null, { status: 200, statusText: statusText(200), response });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/answer-performance': {
          GET: this.findAnswerPerformance,
        },
      },
      this
    );
  }
}
