import _ from 'lodash';

import Communicator from 'models/communicator';
import { getDatabase } from 'scripts/infrastructure/backends/fake_backend/database';
import PhoneCall from 'models/phone_call';
import PhoneControlsActions from 'models/phone_controls_actions';
import qconsole from 'scripts/lib/qconsole';
import RoutingEventType from 'models/routing_event/routing_event_type';

export default class IncomingCallService {
  constructor(pubsub, database = getDatabase) {
    this._pubsub = pubsub;
    this.getDatabase = database;
    this.callQueue = {};
  }

  isCallQueueEmpty() {
    return _.isEmpty(this.callQueue);
  }

  declineIncomingCall(agentId) {
    delete this.callQueue[agentId];
  }

  publishConversationItemUpdate({ orgId, agentId, updatedStatus }) {
    const customerId = this.callQueue[agentId].customerId;
    const customer = _.find(this.getDatabase(orgId).customers, { id: customerId });
    const conversationItem = _.last(customer.conversationHistory);
    conversationItem.content.status = updatedStatus;
    if (!conversationItem.responder) {
      conversationItem.responder = { type: Communicator.AGENT, id: agentId };
    }

    const currentDate = new Date().toISOString();
    if (updatedStatus === PhoneCall.Status.IN_PROGRESS || updatedStatus === PhoneCall.Status.WARM_TRANSFER) {
      conversationItem.content.answeredAt = currentDate;
      const conversation = _.last(customer.conversations);
      conversation.assignee.agentId = agentId;

      IncomingCallService.updateParticipantStatus(
        conversationItem,
        agentId,
        PhoneCall.ParticipantStatus.ACTIVE,
        currentDate
      );
    } else if (updatedStatus === PhoneCall.Status.COMPLETED) {
      conversationItem.content.completedAt = currentDate;
      IncomingCallService.updateParticipantStatus(
        conversationItem,
        agentId,
        PhoneCall.ParticipantStatus.HUNG_UP,
        currentDate
      );

      delete this.callQueue[agentId];
    }
    this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItem.id);
  }

  addCallQualityEvent({ orgId, agentId, event }) {
    const customerId = this.callQueue[agentId].customerId;
    const customer = _.find(this.getDatabase(orgId).customers, { id: customerId });
    const conversationItem = _.last(customer.conversationHistory);
    if (!conversationItem.content.callQualityEvents) {
      conversationItem.content.callQualityEvents = [];
    }
    conversationItem.content.callQualityEvents.push(event);
    this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItem.id);
  }

  static updateParticipantStatus(conversationItem, participantId, status, updatedAt) {
    let participant = _.find(conversationItem.content.participants, { participantId });
    participant.status = status;
    participant.statusUpdatedAt = updatedAt;

    conversationItem.content.participantEvents.push({
      participantId,
      participantStatus: status,
      eventTime: updatedAt,
    });
  }

  publishOfferedCall({ orgId, agentId, customer, conversationId, conversationItem, routingGroupId }) {
    this.callQueue[agentId] = { orgId, customerId: customer.id, conversationItemId: conversationItem.id };
    this.publishResponse(`v1/orgs/${orgId}/agents/${agentId}/routing-event`, {
      type: RoutingEventType.OFFERED_CALL,
      conversationId,
      conversationItem,
      profile: customer.profile,
      routingGroupId,
    });
  }

  publishOfferedWarmTransfer({ orgId, agentId, customer, conversationId, conversationItem, initiator }) {
    this.callQueue[agentId] = { orgId, customerId: customer.id, conversationItemId: conversationItem.id };
    this.publishResponse(`v1/orgs/${orgId}/agents/${agentId}/routing-event`, {
      type: RoutingEventType.OFFERED_WARM_TRANSFER,
      conversationId,
      conversationItem,
      profile: customer.profile,
      initiator,
    });
  }

  publishUnofferedCall({ orgId, agentId, reason }) {
    if (this.isCallQueueEmpty()) {
      qconsole.warn('No incoming call to unoffer');
      return;
    }

    const conversationItemId = this.callQueue[agentId].conversationItemId;
    delete this.callQueue[agentId];
    this.publishResponse(`v1/orgs/${orgId}/agents/${agentId}/routing-event`, {
      type: RoutingEventType.UNOFFERED_CALL,
      conversationItem: {
        id: conversationItemId,
        content: {},
      },
      reason,
    });
  }

  publishResponse(topic, payload) {
    this._pubsub.publish(topic, (payload && { payload }) || {});
  }

  endCallForCurrentAgent() {
    if (!this.isCallQueueEmpty()) {
      const agentId = _.keys(this.callQueue)[0];
      this.handleAgentAction(agentId, PhoneCall.Status.COMPLETED);
    }
  }

  acceptCallForCurrentAgent() {
    if (!this.isCallQueueEmpty()) {
      const agentId = _.keys(this.callQueue)[0];
      const orgId = this.callQueue[agentId].orgId;
      const customerId = this.callQueue[agentId].customerId;
      const customer = _.find(this.getDatabase(orgId).customers, { id: customerId });
      const conversationItem = _.last(customer.conversationHistory);
      let status =
        conversationItem.content.status === PhoneCall.Status.WARM_TRANSFER
          ? PhoneCall.Status.WARM_TRANSFER
          : PhoneCall.Status.IN_PROGRESS;
      this.timeout = setTimeout(() => {
        this.handleAgentAction(agentId, status);
      }, IncomingCallService.participantStatusChangeResponseTimeout / 2);
    }
  }

  updateCallParticipantStatus(orgId, customerId, conversationItemId, attrs) {
    const customer = _.find(this.getDatabase(orgId).customers, { id: customerId });
    const conversationItem = _.find(customer.conversationHistory, { id: conversationItemId });
    const currentDate = new Date().toISOString();
    if (attrs.action === PhoneControlsActions.HOLD) {
      IncomingCallService.handleHoldParticipant(
        conversationItem,
        attrs.parameters.state,
        attrs.parameters.participantId
      );
      this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId);
    } else if (attrs.action === PhoneControlsActions.MUTE) {
      IncomingCallService.handleMuteParticipant(
        conversationItem,
        attrs.parameters.state,
        attrs.parameters.participantId
      );
      this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId);
    } else if (attrs.action === PhoneControlsActions.ADD) {
      IncomingCallService.addParticipant(
        conversationItem,
        attrs.parameters.participantId,
        PhoneCall.ParticipantType.EXTERNAL
      );

      IncomingCallService.updateParticipantStatus(
        conversationItem,
        conversationItem.content.customerNumber,
        PhoneCall.ParticipantStatus.ON_HOLD,
        currentDate
      );

      this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId);
      this.timeout = setTimeout(() => {
        IncomingCallService.updateParticipantStatus(
          conversationItem,
          attrs.parameters.participantId,
          PhoneCall.ParticipantStatus.ACTIVE,
          new Date().toISOString()
        );
        this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId);
      }, IncomingCallService.participantStatusChangeResponseTimeout);
    } else if (attrs.action === PhoneControlsActions.END_CALL) {
      this.endCallForCurrentAgent();
    } else if (attrs.action === PhoneControlsActions.END_CONFERENCE) {
      _.forEach(conversationItem.content.participants, participant => {
        if (participant.participantId !== this.getDatabase(orgId).currentAgent.id) {
          let updatedStatus =
            participant.type === PhoneCall.ParticipantType.CUSTOMER
              ? PhoneCall.ParticipantStatus.ACTIVE
              : PhoneCall.ParticipantStatus.HUNG_UP;
          IncomingCallService.updateParticipantStatus(
            conversationItem,
            participant.participantId,
            updatedStatus,
            currentDate
          );
        }
      });
      this.timeout = setTimeout(() => {
        this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId);
      }, IncomingCallService.participantStatusChangeResponseTimeout);
    } else if (attrs.action === PhoneControlsActions.REMOVE) {
      IncomingCallService.updateParticipantStatus(
        conversationItem,
        attrs.parameters.participantId,
        PhoneCall.ParticipantStatus.HUNG_UP,
        currentDate
      );

      if (attrs.parameters.participantId === this.getDatabase(orgId).currentAgent.id) {
        conversationItem.content.status = PhoneCall.Status.COMPLETED;
        conversationItem.content.completedAt = currentDate;
        delete this.callQueue[attrs.parameters.participantId];
        this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId);
      } else {
        this.timeout = setTimeout(() => {
          this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId);
        }, IncomingCallService.participantStatusChangeResponseTimeout);
      }
    } else if (attrs.action === PhoneControlsActions.BLIND_TRANSFER) {
      const agentId = _.keys(this.callQueue)[0];
      IncomingCallService.updateParticipantStatus(
        conversationItem,
        agentId,
        PhoneCall.ParticipantStatus.HUNG_UP,
        currentDate
      );
      conversationItem.content.status = PhoneCall.Status.QUEUED;

      delete this.callQueue[agentId];
      this.timeout = setTimeout(() => {
        this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId);
      }, IncomingCallService.participantStatusChangeResponseTimeout);
    } else if (attrs.action === PhoneControlsActions.INITIATE_WARM_TRANSFER) {
      let existingTransfereeAgent = _.find(conversationItem.content.participants, {
        participantId: attrs.parameters.toAgentId,
      });
      if (!existingTransfereeAgent) {
        IncomingCallService.addParticipant(
          conversationItem,
          attrs.parameters.toAgentId,
          PhoneCall.ParticipantType.AGENT
        );
      }

      this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId);
      let transfereeAgentStatus = _.sample([PhoneCall.ParticipantStatus.ACTIVE, PhoneCall.ParticipantStatus.NO_ANSWER]);
      this.timeout = setTimeout(() => {
        IncomingCallService.updateParticipantStatus(
          conversationItem,
          attrs.parameters.toAgentId,
          transfereeAgentStatus,
          new Date().toISOString()
        );
        this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId);
      }, IncomingCallService.participantStatusChangeResponseTimeout);
    } else if (attrs.action === PhoneControlsActions.CANCEL_WARM_TRANSFER) {
      this.timeout = setTimeout(() => {
        IncomingCallService.updateParticipantStatus(
          conversationItem,
          attrs.parameters.agentId,
          PhoneCall.ParticipantStatus.HUNG_UP,
          new Date().toISOString()
        );
        this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId);
      }, IncomingCallService.participantStatusChangeResponseTimeout);
      if (attrs.parameters.agentId !== conversationItem.responder.id) {
        delete this.callQueue[attrs.parameters.agentId];
      }
    } else if (attrs.action === PhoneControlsActions.COMPLETE_WARM_TRANSFER) {
      this.timeout = setTimeout(() => {
        IncomingCallService.updateParticipantStatus(
          conversationItem,
          this.getDatabase(orgId).currentAgent.id,
          PhoneCall.ParticipantStatus.HUNG_UP,
          new Date().toISOString()
        );
        this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId);
        delete this.callQueue[this.getDatabase(orgId).currentAgent.id];
      }, IncomingCallService.participantStatusChangeResponseTimeout);
    } else if (attrs.action === PhoneControlsActions.PAUSE_RECORDING) {
      IncomingCallService.handlePauseRecording(conversationItem, attrs.parameters.agentId);
      setTimeout(this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId), 1000);
    } else if (attrs.action === PhoneControlsActions.RESUME_RECORDING) {
      IncomingCallService.handleResumeRecording(conversationItem, attrs.parameters.agentId);
      setTimeout(this.publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId), 1000);
    }
  }

  static addParticipant(conversationItem, participantId, type) {
    let currentDate = new Date().toISOString();
    conversationItem.content.participants.push({
      participantId,
      callId: participantId,
      status: PhoneCall.ParticipantStatus.DIALING,
      statusUpdatedAt: currentDate,
      type,
    });
    conversationItem.content.participantEvents.push({
      participantId,
      participantStatus: PhoneCall.ParticipantStatus.DIALING,
      eventTime: currentDate,
    });
  }

  static handleHoldParticipant(conversationItem, state, participantId) {
    let updatedStatus = state ? PhoneCall.ParticipantStatus.ON_HOLD : PhoneCall.ParticipantStatus.ACTIVE;
    IncomingCallService.updateParticipantStatus(
      conversationItem,
      participantId,
      updatedStatus,
      new Date().toISOString()
    );
  }

  static handleMuteParticipant(conversationItem, state, participantId) {
    let updatedStatus = state ? PhoneCall.ParticipantStatus.MUTED : PhoneCall.ParticipantStatus.ACTIVE;
    IncomingCallService.updateParticipantStatus(
      conversationItem,
      participantId,
      updatedStatus,
      new Date().toISOString()
    );
  }

  static handlePauseRecording(conversationItem, agentId) {
    IncomingCallService.updateRecordingStatus(conversationItem, PhoneCall.RecordingStatus.PAUSED, agentId);
  }

  static handleResumeRecording(conversationItem, agentId) {
    IncomingCallService.updateRecordingStatus(conversationItem, PhoneCall.RecordingStatus.IN_PROGRESS, agentId);
  }

  static updateRecordingStatus(conversationItem, status, agentId) {
    const eventTime = new Date().toISOString();

    conversationItem.content.recordingEvents.push({
      agentId,
      eventTime,
      status,
    });
  }

  publishUpdatedConversationItem(conversationItem, orgId, customerId, conversationItemId) {
    IncomingCallService.incrementConversationItemVersion(conversationItem);
    this.publishResponse(
      `v1/orgs/${orgId}/customer-history/${customerId}/conversation-items/${conversationItemId}`,
      conversationItem
    );
  }

  static get participantStatusChangeResponseTimeout() {
    return 1500;
  }

  updateQueueByCustomer(prevCustomerId, currentCustomerId) {
    const queueItem = _.find(this.callQueue, { customerId: prevCustomerId });
    if (queueItem) {
      queueItem.customerId = currentCustomerId;
    }
  }

  handleAgentAction(agentId, updatedStatus) {
    this.publishConversationItemUpdate({
      orgId: this.callQueue[agentId].orgId,
      agentId,
      updatedStatus,
    });
  }

  static incrementConversationItemVersion(conversationItem) {
    conversationItem.version += 1;
  }
}
