import React from 'react';
import Icon from 'components/lib/icons/icon';

export default function RecommendedAnswersSparkleStrokeIcon(props) {
  return (
    <Icon viewBox="0 0 28 14" {...props}>
      <path
        d="M5.688.938c.228 0 .431.152.533.355l1.523 3.275 3.276 1.524c.203.101.355.304.355.533a.62.62 0 0 1-.355.559L7.744 8.707l-1.523 3.275a.59.59 0 0 1-.534.33.6.6 0 0 1-.558-.33L3.605 8.707.33 7.184A.6.6 0 0 1 0 6.625a.59.59 0 0 1 .33-.533l3.275-1.524L5.13 1.293a.62.62 0 0 1 .559-.355m0 2.082L4.62 5.28a.52.52 0 0 1-.305.304l-2.26 1.041 2.26 1.066q.229.077.305.305l1.066 2.26 1.042-2.26a.46.46 0 0 1 .304-.305l2.26-1.066-2.26-1.041a.52.52 0 0 1-.304-.305zm10.562-.66c0-1.22.99-2.235 2.234-2.235h7.72c.76 0 1.421.66 1.421 1.422v7.312a1.46 1.46 0 0 1-.812 1.295v1.752h.203c.33 0 .609.28.609.61 0 .355-.28.609-.61.609h-8.734a2.005 2.005 0 0 1-2.031-2.031zm2.031 7.921a.82.82 0 0 0-.812.813c0 .457.355.812.812.812h7.313V10.28zm-.812-1.04c.228-.102.508-.178.812-.178h7.922a.2.2 0 0 0 .203-.204V1.547a.22.22 0 0 0-.203-.203h-7.719c-.584 0-1.015.457-1.015 1.015zm2.64-6.272h4.47c.33 0 .608.28.608.61a.6.6 0 0 1-.609.608H20.11a.587.587 0 0 1-.609-.609c0-.33.254-.61.61-.61m0 2.031h4.47c.33 0 .608.28.608.61a.6.6 0 0 1-.609.609h-4.47a.587.587 0 0 1-.609-.61c0-.33.254-.609.61-.609"
        fill="#8C69F0"
      />
    </Icon>
  );
}
