import React from 'react';
import PropTypes from 'prop-types';

export function Number({ value, minDecimalPlaces, maxDecimalPlaces, zeroValue, className, isPercentage }) {
  const effectiveMaxDecimalPlaces = maxDecimalPlaces < minDecimalPlaces ? minDecimalPlaces : maxDecimalPlaces;
  const parsedValue = !isNaN(parseFloat(value)) ? parseFloat(value) : null;

  return (
    <span className={className}>
      {parsedValue !== null
        ? parsedValue.toLocaleString(undefined, {
            minimumFractionDigits: minDecimalPlaces,
            maximumFractionDigits: effectiveMaxDecimalPlaces,
            style: isPercentage ? 'percent' : undefined,
          })
        : zeroValue}
    </span>
  );
}

Number.propTypes = {
  value: PropTypes.number,
  minDecimalPlaces: PropTypes.number,
  maxDecimalPlaces: PropTypes.number,
  zeroValue: PropTypes.string,
  className: PropTypes.string,
  isPercentage: PropTypes.bool,
};

Number.defaultProps = {
  minDecimalPlaces: 0,
  maxDecimalPlaces: 0,
  zeroValue: '0',
  className: '',
  isPercentage: false,
};

export default Number;
