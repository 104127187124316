import RecommendedAnswers from 'models/location/recommended_answers';
import OpenRecommendedAnswers from 'actions/recommended_answers/open_recommended_answers';
import { registerUrlConverter } from './location_url';
import StaticUrlConverter from './url_converters/static_url_converter';

registerUrlConverter(RecommendedAnswers, StaticUrlConverter.create('/recommended-answers'));

export default function(router, { runnerContext }) {
  return {
    on() {
      runnerContext.executeAction(OpenRecommendedAnswers);
    },
  };
}
