import createModel, { prop } from './lib/create_model';

const AnswerPerformance = createModel({
  modelName: 'AnswerPerformance',
  properties: {
    id: prop(String).isRequired,
    name: prop(String).isRequired,
    references: prop(Number).isRequired,
    handOffs: prop(Number).isRequired,
    resolved: prop(Number).isRequired,
    resolutionRate: prop(Number).isRequired,
  },

  statics: {
    create(attrs) {
      return new this(attrs);
    },
  },
});

export default AnswerPerformance;
