import _ from 'lodash';
import moment from 'moment';

import AgentPhonePreferences from 'models/agent_phone_preferences';
import CommunicationSimulator from './communication_simulator';
import Communicator from 'models/communicator';
import Conversation from 'models/conversation';
import CustomerConverter from 'scripts/application/dto_converters/customer_converter';
import Factory from 'factories/all';
import IdGenerator from 'factories/lib/id_generator';
import OfferedCallPublisher from './offered_call_publisher';
import PhoneCall from 'models/phone_call';
import qconsole from 'scripts/lib/qconsole';
import UnofferedCallType from 'models/routing_event/unoffered_call_type';

export default class IncomingPhoneCallSimulator extends CommunicationSimulator {
  constructor({ orgId, fakeTwilio, services }, database) {
    super(
      {
        attribute: 'incomingPhoneCalls',
        createItem: createIncomingPhoneCall,
        orgId,
        publisher: new OfferedCallPublisher(orgId, services.incomingCall, database),
        services,
      },
      database
    );
    this._fakeTwilio = fakeTwilio;
  }

  simulate(communication) {
    if (!this.incomingCallService.isCallQueueEmpty()) {
      qconsole.warn('Current agent has an active phone call');
      return;
    }
    this._fakeTwilio.simulateIncomingCall();
    super.simulate(communication);
  }

  simulateCallQualityEvent(event) {
    if (this.incomingCallService.isCallQueueEmpty()) {
      qconsole.warn('No active call to add call quality event');
      return;
    }

    this.incomingCallService.addCallQualityEvent({
      orgId: this.orgId,
      agentId: this.database.get().currentAgent.id,
      event,
    });
  }

  simulateEndCall() {
    if (this.incomingCallService.isCallQueueEmpty()) {
      qconsole.warn('No active call to hangup');
      return;
    }
    this.incomingCallService.publishConversationItemUpdate({
      orgId: this.orgId,
      agentId: this.database.get().currentAgent.id,
      updatedStatus: PhoneCall.Status.COMPLETED,
    });
  }

  simulateAccepted() {
    if (this.incomingCallService.isCallQueueEmpty()) {
      qconsole.warn('No incoming call to accept');
      return;
    }

    this.incomingCallService.publishConversationItemUpdate({
      orgId: this.orgId,
      agentId: this.database.get().currentAgent.id,
      updatedStatus: PhoneCall.Status.IN_PROGRESS,
    });
  }

  simulatePhoneCallUnoffered() {
    this.incomingCallService.publishUnofferedCall({
      orgId: this.orgId,
      agentId: this.database.get().currentAgent.id,
      reason: _.sample(UnofferedCallType),
    });
  }

  get incomingCallService() {
    return this.services.incomingCall;
  }

  _getCustomer(communication) {
    return this.createCustomerProfileWithNumberAndStatus(communication.customerNumber, this.getCustomerProfileStatus());
  }

  createCustomerProfileWithNumberAndStatus(phoneNumber, status) {
    const customerId = IdGenerator.newId();
    let profile = {
      id: customerId,
      status,
      conversations: [],
      conversationHistory: [],
      phones: [{ original: phoneNumber, regionCode: 'US', normalized: phoneNumber }],
    };
    if (status === 'VERIFIED') {
      _.extend(profile, { name: 'Darth Vader' });
    }
    const customer = Factory.build('Customer', { id: customerId, profile });
    this.database.get().customers.push(customer);
    return customer;
  }

  getCustomerProfileStatus() {
    return 'UNVERIFIED';
  }

  _findConversation(communication, customer) {
    let conversation = this._findOpenConversation(customer);
    if (conversation) {
      conversation.assignee.agentId = null;
    }
    return conversation;
  }

  _findOpenConversation(customerDto) {
    var customer = CustomerConverter.fromDto(customerDto);
    var conversation = _.find(customer.conversations, conversation => conversation.status === Conversation.Status.OPEN);
    return conversation ? _.find(customerDto.conversations, { id: conversation.id }) : null;
  }
}

export function createIncomingPhoneCall(incomingPhoneCall, customerId) {
  let currentDate = new Date().toISOString();
  return Factory.build('PhoneCallItem', {
    initiator: {
      type: Communicator.CUSTOMER,
      id: customerId,
    },
    customerId,
    content: {
      customerNumber: incomingPhoneCall.customerNumber,
      companyNumber: incomingPhoneCall.companyNumber,
      status: PhoneCall.Status.OFFERING,
      startedAt: currentDate,
      participants: [
        {
          participantId: incomingPhoneCall.customerNumber,
          callId: incomingPhoneCall.customerNumber,
          status: PhoneCall.ParticipantStatus.ACTIVE,
          statusUpdatedAt: currentDate,
          type: PhoneCall.ParticipantType.CUSTOMER,
        },
        {
          participantId: this.database.get().currentAgent.id,
          callId: this.database.get().currentAgent.id,
          callLegType: AgentPhonePreferences.BROWSER,
          status: PhoneCall.ParticipantStatus.DIALING,
          statusUpdatedAt: currentDate,
          type: PhoneCall.ParticipantType.AGENT,
        },
      ],
      participantEvents: [
        {
          participantId: incomingPhoneCall.customerNumber,
          participantStatus: PhoneCall.ParticipantStatus.DIALING,
          eventTime: moment(currentDate)
            .subtract(1000, 'ms')
            .toISOString(),
        },
        {
          participantId: incomingPhoneCall.customerNumber,
          participantStatus: PhoneCall.ParticipantStatus.ACTIVE,
          eventTime: currentDate,
        },
        {
          participantId: this.database.get().currentAgent.id,
          participantStatus: PhoneCall.ParticipantStatus.DIALING,
          eventTime: currentDate,
        },
      ],
      recordingEvents: [
        {
          agentId: this.database.get().currentAgent.id,
          eventTime: currentDate,
          status: PhoneCall.RecordingStatus.IN_PROGRESS,
        },
      ],
    },
    timestamp: currentDate,
  });
}
