import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AgentPhonePreferences from 'models/agent_phone_preferences';
import connect from 'components/lib/connect';
import OutsideClickHandler from 'components/common/utilities/outside_click_handler';

export function CallQualityWarning({ isCallQualityIssueDetected }) {
  const [isTroubleshootingDropdownVisible, setIsTroubleshootingDropdownVisible] = useState(false);
  const onBadgeClick = () => {
    setIsTroubleshootingDropdownVisible(!isTroubleshootingDropdownVisible);
  };

  const onClickOutside = () => {
    setIsTroubleshootingDropdownVisible(false);
  };

  // Do not hide the warning if the dropdown is visible
  if (!isCallQualityIssueDetected && !isTroubleshootingDropdownVisible) {
    return null;
  }

  return (
    <OutsideClickHandler onClickOutside={onClickOutside}>
      <CallQualityWarningBadgeContainer>
        <CallQualityWarningBadge data-aid="call-quality-warning-badge" onClick={onBadgeClick}>
          Unstable connection
        </CallQualityWarningBadge>
        {isTroubleshootingDropdownVisible && <TroubleshootingDropdown />}
      </CallQualityWarningBadgeContainer>
    </OutsideClickHandler>
  );
}

export function TroubleshootingDropdown() {
  return (
    <TroubleshootingDropdownContainer>
      <TroubleshootingDropdownHeader>Unstable connection</TroubleshootingDropdownHeader>
      <TroubleshootingDropdownBody>
        <TroubleshootingDropDownSection>
          Try connecting to a different WiFi network if available, or use a wired internet connection. If needed,
          restart your call.
        </TroubleshootingDropDownSection>
        <TroubleshootingDropDownSection>
          <div>Still experiencing an issue?</div>
          <HelpLink
            href="https://help.gladly.com/docs/my-call-quality-is-bad-what-should-i-do"
            rel="noopener noreferrer"
            target="_blank"
          >
            Help documentation
          </HelpLink>
        </TroubleshootingDropDownSection>
      </TroubleshootingDropdownBody>
    </TroubleshootingDropdownContainer>
  );
}

const CallQualityWarningBadgeContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  align-self: center;
  height: fit-content;
`;

const CallQualityWarningBadge = styled.div`
  display: inline-flex;
  align-items: center;
  align-self: center;
  cursor: pointer;
  padding: ${p => p.theme.spacing.xsmall} ${p => p.theme.spacing.medium};
  border-radius: 20px;
  background-color: ${p => p.theme.colors.yellow100};
  font-size: ${p => p.theme.fontSize.small};
  font-weight: ${p => p.theme.fontWeight.medium};
  height: fit-content;
`;

const TroubleshootingDropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background-color: ${p => p.theme.colors.white};
  border-radius: ${p => p.theme.borderRadius.large};
  box-shadow: ${p => p.theme.boxShadow.medium};
  font-size: ${p => p.theme.fontSize.base};
  line-height: 21px;
  padding: ${p => p.theme.spacing.insetMedium};
  text-wrap: wrap;
  width: 308px;
  z-index: 10;
`;

const TroubleshootingDropdownHeader = styled.div`
  color: ${p => p.theme.colors.yellow600};
  font-weight: ${p => p.theme.fontWeight.heavy};
`;

const TroubleshootingDropdownBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TroubleshootingDropDownSection = styled.div``;

const HelpLink = styled.a`
  text-decoration: none;
`;

CallQualityWarning.propTypes = {
  isCallQualityIssueDetected: PropTypes.bool,
};

function mapStateToProps({ getProvider }) {
  const activeCall = getProvider('activeCall').get();
  if (!activeCall) {
    return {
      isCallQualityIssueDetected: false,
    };
  }
  const currentAgent = getProvider('currentAgent').get();
  const agentVoicePreference = currentAgent.getVoiceConfigurationPreference();
  if (
    !(agentVoicePreference === AgentPhonePreferences.BROWSER || agentVoicePreference === AgentPhonePreferences.NONE)
  ) {
    return {
      isCallQualityIssueDetected: false,
    };
  }
  const activeCallQualityEvents = activeCall.conversationItem.content.getActiveCallQualityEventsForAgent(
    currentAgent.id
  );
  const isCallQualityIssueDetected = activeCallQualityEvents.length >= 3 || activeCallQualityEvents.includes('low-mos');
  return {
    isCallQualityIssueDetected,
  };
}

const CallQualityWarningContainer = connect(mapStateToProps)(CallQualityWarning);

export default CallQualityWarningContainer;
